export default (theme) => ({
  header: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    background: (props) =>
      props.backgroundImage
        ? `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${props.backgroundImage}) no-repeat center/cover`
        : props.backgroundColor,
    backdropFilter: (props) => (props.backgroundImage ? 'blur(10px)' : 'none'),
    padding: '16px 24px',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: '100px',
  },
  headerContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 110,
    gap: 16,
  },
  headerTitle: {
    fontWeight: 800,
    color: theme.palette.common.white,
    textTransform: 'none',
  },
  headerStatus: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  statusText: {
    color: theme.palette.common.white,
    borderRadius: '4px',
    fontSize: '14px',
    fontWeight: 600,
  },
  subtitle: {
    color: theme.palette.common.white,
  },
});
