export default (theme) => ({
  mainWrapper: {
    display: 'flex',
    justifyContent: 'end',
    padding: '10px 0px',
    borderBottom: '1px solid ' + theme.palette.blue?.pale,
  },
  msgIcon: {
    fontSize: '18px !important',
  },
  markReadBtn: {
    height: 43,
    padding: '0px 30px !important',
    fontSize: '18px !important',
    minWidth: 208,
  },
  newBtn: {
    backgroundColor: theme.palette.blue?.primary,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.blue?.primary,
    },
  },
});
