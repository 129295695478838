import { Box, CircularProgress, Snackbar, IconButton, withStyles } from '@material-ui/core';
import React from 'react';
import styles from './mark-read.style';
import { Button } from '@orijinworks/frontend-commons';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import { useMarkReadHook } from './useMarkReadHook';
import MuiAlert from '@material-ui/lab/Alert';
import { FormattedMessage } from 'react-intl';

const MarkRead = (props) => {
  const { classes } = props;
  const { isDisabled, isLoading, markAllReadHandler, handleSnackbarToggle, snackbarMessage, snackbarType, isSnackbarOpen } =
    useMarkReadHook(props);
  return (
    <>
      <Box className={classes.mainWrapper}>
        <Button
          id="mark-all-read-btn"
          onClick={markAllReadHandler}
          variant="outlined"
          color="primary"
          disabled={isDisabled || isLoading}
          className={classes.markReadBtn}
        >
          {isLoading ? (
            <CircularProgress size={20} />
          ) : (
            <>
              <MarkEmailReadIcon className={classes.msgIcon} /> &nbsp;
              <FormattedMessage id="markAllRead" />
            </>
          )}
        </Button>
      </Box>

      <Snackbar open={isSnackbarOpen} autoHideDuration={6000} onClose={handleSnackbarToggle}>
        <MuiAlert severity={snackbarType} elevation={6} variant="filled">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default withStyles(styles)(MarkRead);
