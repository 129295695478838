export default (theme) => ({
  root: {
    padding: 20,
  },
  disableEditor: {
    pointerEvents: 'none',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 5,
    caretColor: 'black !important',
  },
  editorContainer: {
    padding: 20,
    minHeight: 100,
    maxHeight: '20dvh',
    overflowY: 'auto',
    '&:focus-within': {
      outline: `2px solid ${theme.palette.aqua.deep}`,
    },
  },
  blurEditorContent: {
    '& > div:first-child': {
      filter: 'blur(5px)',
    },
  },
  disabledEditor: {
    opacity: 0.35,
    backgroundColor: theme.palette.colors.inputBackground,
    cursor: 'not-allowed',
  },
  actionContainer: {
    borderTop: `1px solid ${theme.palette.primary.light}`,
    padding: 20,
    display: 'flex',
    justifyContent: 'space-between',
  },
  toggleBlurBox: {
    border: `1px solid ${theme.palette.colors.orijinPurple}`,
    borderRadius: '50%',
    padding: 5,
  },
  toggleBlurButton: {
    fill: theme.palette.colors.orijinPurple,
  },
  sendButton: {
    fontWeight: 700,
    fill: theme.palette.primary.white,
    textTransform: 'capitalize',
  },
  disabledButton: {
    opacity: 0.35,
    color: `${theme.palette.primary.white} !important`,
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
  loader: {
    color: theme.palette.primary.white,
  },
  helperContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 10,
  },
  helperText: {
    textTransform: 'capitalize',
    color: theme.palette.text.sub,
  },
  errorText: {
    color: theme.palette.primary.darkRed,
  },
});
