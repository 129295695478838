import { useCallback, useEffect, useState } from 'react';
import { store, toggleToast } from '../../../../../core';
import {
  CAREER_COMPASS_CONSTANTS,
  fetchCurrentStep,
  updateCurrentStep,
  isStepProgramProgression,
} from '../../services/career-compass.service';

/**
 * Custom hook for the Career Compass component
 *
 * @param {object} currentStep - The current step
 * @returns {object} - The hook methods and data
 */
const useCareerCompassHook = (currentStep) => {
  const [programId, setProgramId] = useState(null);

  /**
   * Effect to fetch the current step and programs.
   * It will only fetch the current step if it is not already fetched.
   */
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (currentStep === null) {
          await fetchCurrentStep();
        }
        if (currentStep?.selectedProgramId) setProgramId(currentStep.selectedProgramId);
      } catch (error) {
        console.error('Error fetching data', error);
      }
    };

    fetchData();
  }, [currentStep]);

  /**
   * Function to check if the introduction guide should be shown
   *
   * @returns {boolean} - The introduction guide status
   */
  const showIntroductionGuide = useCallback(() => {
    return currentStep?.name === CAREER_COMPASS_CONSTANTS.STEPS.INTRODUCTION;
  }, [currentStep]);

  /**
   * Function to check if the program selection should be shown
   *
   * @returns {boolean} - The program selection status
   */
  const showProgramSelection = useCallback(() => {
    return (
      isStepProgramProgression(currentStep) &&
      (currentStep.state === CAREER_COMPASS_CONSTANTS.STEP_STATE.NOT_STARTED ||
        currentStep.state === CAREER_COMPASS_CONSTANTS.STEP_STATE.COMPLETED)
    );
  }, [currentStep]);

  /**
   * Function to check if the program details should be shown
   *
   * @returns {boolean} - The program details status
   */
  const showProgramDetails = useCallback(() => {
    return (
      isStepProgramProgression(currentStep) &&
      Boolean(currentStep.state) &&
      currentStep.state !== CAREER_COMPASS_CONSTANTS.STEP_STATE.NOT_STARTED &&
      currentStep.state !== CAREER_COMPASS_CONSTANTS.STEP_STATE.COMPLETED &&
      Boolean(programId)
    );
  }, [currentStep, programId]);

  /**
   * Function to handle the current step update.
   * It will update the step as per the provided data.
   * It will show a toast message if there is an error.
   *
   * @param {string} state - The state to update
   * @param {object} [userData] - Optional user data to include in the update
   */
  const handleCurrentStepUpdate = async (state, userData) => {
    try {
      const data = { stepId: currentStep.id, state, ...(userData && { userData }) };
      await updateCurrentStep(data);
      fetchCurrentStep();
    } catch (e) {
      store.dispatch(
        toggleToast({
          variant: 'error',
          message: 'error.msg.default',
          title: 'sorry',
          isOpen: true,
          showCancelButton: true,
        })
      );
    }
  };

  /**
   * Effect to disable the body scroll
   */
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  return {
    programId,
    showIntroductionGuide,
    showProgramSelection,
    showProgramDetails,
    handleCurrentStepUpdate,
  };
};

export default useCareerCompassHook;
