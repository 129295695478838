import moment from 'moment-timezone';

import ReactGA from 'react-ga';
import { Http, Tracker } from '../../../core/services';
import { PlayMainService } from '../../../content/play/services';
import { Constant } from '../../../shared/services/';
import { showHideLoader, store } from '../../../core';

export class UserService {
  static userServerTimestamp = null;
  static userDateTime = null;
  static customDateIntevalRef = null;
  static isLogoutInitiated = false;
  static GAEvents = {
    Category: {
      Pathways: 'Pathways',
      Explore: 'Explore',
    },
    CLICKED: 'Clicked',
    WATCHED: 'watched',
  };
  _tracker = new Tracker();
  isTimeLoading = false;
  currentTimeErrorThreadRef = null;
  userLocalTimeDelta = null;
  refetchDeltaThreshold = 10; // SECONDS
  format = 'YYYY-MM-DD HH:mm:ss';
  _playMainService = new PlayMainService();

  /**
   * @name callingDidMount
   * @desc This method will be fired when component did Mount.
   * @return {void}
   */
  callingDidMount = () => {
    // APPENDING EVENT
    // ON WINDOW / DOCUMENT
    window.onfocus = this.appOnFocus;
    document.addEventListener('visibilitychange', this.onVisibilityChange, false);
  };

  /**
   * @name removeEvents
   * @desc This method will be fired when calling component will be unmounted.
   * @return {void}
   */
  callingWillUnmount = () => {
    // REMOVING EVENT
    // FROM WINDOW / DOCUMENT
    window.onfocus = null;
    document.removeEventListener('visibilitychange', this.onVisibilityChange);
  };

  /**
   * @name appOnFocus
   * @desc Fire's each time application window
   * is focused.
   * @return {void}
   */
  appOnFocus = async () => {
    this.forceFetchTime();
  };

  /**
   * @name onVisibilityChange
   * @desc Fire's on visibility of app changes
   * @return {void}
   */
  onVisibilityChange = () => {
    if (document.hidden) {
      this._tracker.endLastSession();
    } else {
      this._tracker.append(window.location.pathname + window.location.search);
    }
    if (!document.hidden) {
      this.forceFetchTime();
    }
  };

  /**
   * @name forceFetchTime
   * @desc Force fully fetch's from server.
   * @return {void}
   */
  forceFetchTime = async () => {
    try {
      const timeDelta = this.calculateUserTimeDelta(UserService.userDateTime);
      const hasThresholdExceed = Math.abs(timeDelta - this.userLocalTimeDelta) > this.refetchDeltaThreshold;
      if (this.isTimeLoading || !hasThresholdExceed) {
        return;
      }
      await this.getUserTimeMeta(true);
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * @name getEarnTokenRules
   * @desc Fetches facility / location rules for earn token.
   * @return {Promise}
   */
  getEarnTokenRules = () => {
    return new Promise((resolve, reject) => {
      Http.REQUEST.get('/service/rules').then((_successLog) => resolve(_successLog.data));
    });
  };

  /**
   * @name maintainLocalTime
   * @desc A utility method that starts a interval
   * and keeps increasing time on each second and maintain's user local time
   * in app.
   * @return {void}
   */
  maintainLocalTime = () => {
    let timeDelta = 0;
    try {
      if (!UserService.customDateIntevalRef) {
        UserService.customDateIntevalRef = setInterval(() => {
          UserService.userDateTime = moment(UserService.userDateTime).add(1, 'second').format(this.format);
          this.forceFetchTime(); // WILL ONLY FETCH IF THRESHOLD HAS EXCEEDED
        }, 1000);
      }
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * @name calculateUserTimeDelta
   * @param {string} userDateTimeFormatted
   * @desc Calculate's delta b/w userDateTimeFormatted & user local time.
   * @return {number} delta
   */
  calculateUserTimeDelta = (userDateTimeFormatted) => {
    const ms = moment(userDateTimeFormatted).diff(moment());
    const d = moment.duration(ms);
    return Math.abs(Math.round(d.asSeconds()));
  };

  /**
   * @name getUserTimeMeta
   * @param {boolean} isForceFetch If true then cache date won't be considered.
   * @desc Fetches user time from third party service, in order to make sure
   *        that no throtling between time is done.
   * @return {Promise}
   */
  getUserTimeMeta = (isForceFetch = false) => {
    let queryParams = new URLSearchParams();
    return new Promise((resolve) => {
      // AVODING XHR REQUEST
      // IF userDateTime IS ALREADY
      // AVAILABLE
      if (UserService.userDateTime && !isForceFetch) {
        resolve({
          datetime: UserService.userDateTime,
        });
        return;
      }
      if (isForceFetch) {
        queryParams.append('noLoader', 'true');
      }
      this.isTimeLoading = true;
      Http.REQUEST.get(`/location/currentTime?${queryParams.toString()}`).then(
        (_successLog) => {
          // SETTING USER SERVER TIME
          // IN PURE FORM
          UserService.userServerTimestamp = _successLog.data;

          UserService.userDateTime = moment
            .parseZone(_successLog.data.timeZone)
            .tz(moment.tz.guess(), true)
            .format(this.format);
          this.maintainLocalTime();
          this.userLocalTimeDelta = this.calculateUserTimeDelta(UserService.userDateTime);
          this.isTimeLoading = false;
          resolve(_successLog.data);
        },
        (_errorLog) => {
          UserService.userDateTime = moment().format(this.format);
          this.maintainLocalTime();
          this.isTimeLoading = false;
          clearTimeout(this.currentTimeErrorThreadRef);
          this.currentTimeErrorThreadRef = null;
          this.currentTimeErrorThreadRef = setTimeout(() => {
            // RECURSIVELY GETTING TIME
            // IN CASE FETCH TIME FAILS
            this.forceFetchTime();
          }, Constant.FALLBACK_TIME_FETCH);
          resolve({
            datetime: moment().format(this.format),
          });
        }
      );
    });
  };

  /**
   * @name queryUser
   * @desc Queires for user meta information from server.
   * @return {Promise}
   */
  queryUser = () => {
    return new Promise(async (resolve, reject) => {
      const userInfo = await Http.REQUEST.get('/user');
      if (!userInfo || !userInfo.data) {
        reject(false);
        return;
      }
      resolve(userInfo.data);
    });
  };

  /**
   * @name getUserCard
   * @desc Get the user information and crediential.
   * @return {Promise}
   */
  getUserCard = () => {
    return new Promise(async (resolve, reject) => {
      const userInfo = await Http.REQUEST.get('/student/usercard');
      if (!userInfo || !userInfo.data) {
        reject(false);
        return;
      }
      resolve(userInfo.data);
    });
  };

  /**
   * @name getUserToken
   * @desc Fetches user token.
   * This token is visible on header and checks for last activity date.
   * @return {Promise}
   */
  getUserToken = () => {
    return new Promise((resolve, reject) => {
      Http.REQUEST.get('/user/token/balance?noLoader=true')
        .then((_successLog) => resolve(_successLog.data))
        .catch((_errorLog) => reject(_errorLog));
    });
  };

  /**
   * @name getFeatures
   * @desc Fetches application features.
   * @return {Promise}
   */
  getFeatures = (queryParams) => {
    return new Promise((resolve, reject) => {
      Http.REQUEST.get(`/feature/locationFeatures${queryParams}`)
        .then((_successLog) => resolve(_successLog.data))
        .catch((_errorLog) => reject(_errorLog));
    });
  };

  /**
   * @name getUserTransaction
   * @desc Fetches user transaction based upon last activity is done.
   * (Can be found in getUserToken method).
   * @return {Promise}
   */
  getUserTransaction = () => {
    return new Promise((resolve, reject) => {
      Http.REQUEST.get('/user/transactions?allTransactionTypes=true&noLoader=true')
        .then((_successLog) => resolve(_successLog.data))
        .catch((_errorLog) => reject(_errorLog));
    });
  };

  /**
   * @name getUnreadMessagesCount
   * @desc Fetches user unread messages count.
   * @return {Promise}
   */
  getUnreadMessagesCount = () => {
    return new Promise((resolve, reject) => {
      Http.HERMES_REQUEST.get(`/messages/unread_count?noLoader=true`)
        .then((_successLog) => resolve(_successLog.data))
        .catch((_errorLog) => reject(_errorLog));
    });
  };

  /**
   * @name getFnFUnreadMessagesCount
   * @desc Fetches user unread messages count.
   * @return {Promise}
   */
  getFnFUnreadMessagesCount = (learnerId) => {
    return new Promise((resolve, reject) => {
      Http.HERMES_REQUEST.get(
        `/services/messages/friends-family/v1/learner/messages/${learnerId}/unread-count?noLoader=true`
      )
        .then((_successLog) => resolve(_successLog.data))
        .catch((_errorLog) => console.log(_errorLog));
    });
  };

  /**
   * @name getSAMLLogoutURLs
   * @desc Fetches manual logout URLs and creates dynamic iframe for logout. Resolves when all Iframe returns 200.
   * @return Promise
   */
  logoutOnPartnerSystems = () => {
    store.dispatch(
      showHideLoader({
        isLoading: true, //NO NEED TO SWITCH IT BACK OFF SINCE ITS ALREADY LOGGING OUT
      })
    );
    return new Promise((resolve, reject) => {
      Http.REQUEST.get('/user/unauth/saml-logout-urls?noLoader=true')
        .then((_successLog) => {
          const { data } = _successLog;
          data.forEach(({ logoutUrl, logoutFrameType }) => {
            switch (logoutFrameType) {
              case '_blank':
                let partnerLogoutWindow = window.open(logoutUrl, '_blank', 'width=20px, height=20px');
                setTimeout(() => {
                  partnerLogoutWindow.close();
                }, Constant.PARTNER_LOGOUT_WINDOW_TIME);
                break;

              case '_self':
              default:
                const iframe = document.createElement('iframe');
                iframe.setAttribute('src', logoutUrl);
                document.body.appendChild(iframe);
            }
          });
          setTimeout(() => {
            resolve(true);
          }, Constant.PARTNER_LOGOUT_WINDOW_TIME);
        })
        .catch((_errorLog) => {
          console.log(_errorLog);
          reject(false);
        });
    });
  };

  /**
   * @name getRequiredFormsCount
   * @desc Fetches required form counts if counter is greater then 0 then user will not
   * be allow to navigate any screen except forms
   * @return {Promise}
   */
  getRequiredFormsCount = () => {
    return new Promise((resolve, reject) => {
      Http.REQUEST.get('/form/user/pending_submission?noLoader=true')
        .then((_successLog) => resolve(_successLog.data))
        .catch((_errorLog) => reject(_errorLog));
    });
  };

  /**
   * @name getLearnerSchedule
   * @desc Fetches all schedule from server and further prepares
   * for view rendering purpose.
   * @return {Promise}
   */
  getLearnerSchedule = (params = '') => {
    return new Promise((resolve, reject) => {
      Http.REQUEST.get(`/feature/location/schedule${params}`).then((_successLog) => {
        const refinedScheduleData = this._playMainService.refineScheduleData(_successLog.data);
        resolve(this._playMainService.prepareSchedule('Learner_Portal_Access_Schedule', refinedScheduleData).items);
      });
    });
  };

  /**
   * @name refreshSSOAttributes
   * @desc Initiates a GET call to server and server refreshes
   * the user attributes based upon certain underlying logics.
   * @return {void}
   */
  refreshSSOAttributes = async () => {
    try {
      await Http.REQUEST.get('/user/refreshssoattributes');
    } catch (e) {
      console.error('ERROR WHILE REFRESHING USER SSO ATTRIBUETS', e);
    }
  };

  /**
   * @name registerAnalyticEvent
   * @param {string} category
   * @param {string} action
   * @param {number | null} value
   * @param {string} label
   * @desc Event is being registered in google analytics dashboard using
   * ReactGA.event method.
   * @return {void}
   */
  registerAnalyticEvent = (category, action, value, label) => {
    ReactGA.event({
      category,
      action,
      value,
      label,
    });
  };

  /**
   * @name registerAnalyticUserTiming
   * @param {string} category
   * @param {string} action
   * @param {number | null} value
   * @param {string} label
   * @desc UserTiming is being registered in google analytics dashboard using
   * ReactGA.timing method.
   */
  registerAnalyticUserTiming = (category, variable, value, label) => {
    ReactGA.timing({
      category,
      variable,
      value,
      label,
    });
  };
}
