import { useRef } from 'react';
import { CAREER_COMPASS_CONSTANTS } from '../../services/career-compass.service';

const useProgramDetailsContainerHook = ({ unleashProps, currentStep, selectedProgram, handleCurrentStepUpdate }) => {
  const expandCollapseSectionsRef = useRef();
  const isFeatureFlagEnabled = unleashProps?.isFlagEnabled();

  const expandTopSection = () => {
    expandCollapseSectionsRef.current.expandTopSection();
  };

  const expandBottomSection = () => {
    expandCollapseSectionsRef.current.expandBottomSection();
  };

  const isCertificateAwarded = () => {
    return selectedProgram?.certificateUrl;
  };

  const showExpandCollapseSections = () => {
    return isFeatureFlagEnabled && (!currentStep.isFirstTime || isCertificateAwarded());
  };

  const hasMorePrograms = () => Boolean(currentStep?.nextStep);

  const onPickNextProgram = async () => {
    handleCurrentStepUpdate(CAREER_COMPASS_CONSTANTS.STEP_STATE.COMPLETED);
  };

  return {
    expandCollapseSectionsRef,
    isFeatureFlagEnabled,
    expandTopSection,
    expandBottomSection,
    showExpandCollapseSections,
    hasMorePrograms,
    onPickNextProgram,
    isCertificateAwarded,
  };
};

export default useProgramDetailsContainerHook;
