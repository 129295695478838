export default (theme) => ({
  root: {
    fontFamily: 'Inter',
  },
  mainFilters: {
    borderRadius: '0 0 16px 16px',
    backgroundColor: 'white',
    width: '100%',
    padding: 24,
  },
  filterArea: {
    paddingTop: 0,
  },
  centerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  searchBarWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  searchBar: {
    backgroundColor: 'white',
    border: `1px solid ${theme.palette.greyScale.grey70}`,
    height: 40,
    borderRadius: 50,
    '& input::placeholder': {
      color: theme.palette.greyScale.grey90,
      opacity: 1,
    },
    '&:focus-within': {
      outline: `2px solid ${theme.palette.primary.active}`,
    },
    '& svg': {
      color: theme.palette.blue.main,
      height: 16,
      width: 16,
    },
  },
  searchBtn: {
    ...theme.typography.t16,
    textTransform: 'capitalize',
    marginLeft: 15,
    paddingLeft: 35,
    paddingRight: 35,
    height: 40,
    borderRadius: 100,
    backgroundColor: theme.palette.blue.main,
    '&:hover': {
      backgroundColor: theme.palette.blue.main,
      filter: 'brightness(80%)',
      boxShadow: 'none',
    },
    boxShadow: 'none',
  },
  categoryFilter: {
    borderRadius: 50,
    border: `1px solid ${theme.palette.greyScale.grey70}`,
    '&:focus-within': {
      outline: `2px solid ${theme.palette.primary.active}`,
    },
  },
  categorySelect: {
    minWidth: 300,
    height: 40,
    backgroundColor: 'white',
    borderRadius: 50,
    '&:before': {
      borderBottom: 'none',
    },
    '&:after': {
      borderBottom: 'none',
    },
    '&:hover': {
      borderBottom: 'none',
    },
    '&:focus': {
      borderBottom: 'none',
    },
    '&:active': {
      borderBottom: 'none',
    },
  },
  selectRoot: {
    borderRadius: 50,
    backgroundColor: 'white',
  },
  selectOption: {
    color: theme.palette.text.primary,
    padding: '0 6px',
    margin: '0 10px',
    backgroundColor: 'white',
    '&:focus': {
      backgroundColor: 'white',
    },
  },
  categorySelectLabel: {
    ...theme.typography.t16,
    color: theme.palette.greyScale.grey90,
    fontWeight: 400,
    padding: '0px 10px',
  },
  selectMenu: {
    backgroundColor: 'white',
    '& option': {
      padding: 10,
      cursor: 'pointer',
      fontWeight: 500,
      '&:hover': {
        backgroundColor: theme.palette.colors.inputBackground,
      },
    },
  },
  clearFiltersBtn: {
    ...theme.typography.t16,
    fontWeight: 700,
    textTransform: 'capitalize',
    borderRadius: 30,
    height: 40,
    color: theme.palette.aqua.extraDeep,
  },
  noBorder: {
    border: 'none',
  },
  bold: {
    fontWeight: 700,
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  bolder: {
    fontWeight: 500,
  },
  marginTop20: {
    marginTop: 20,
  },
  mainDescription: {
    color: theme.palette.text.sub,
    fontSize: '1rem',
  },
  zoneSubtitle: {
    display: 'flex',
  },
  learnMoreZone: {
    textDecoration: 'underline',
    cursor: 'pointer',
    fontWeight: 500,
    marginLeft: 5,
  },
  zoneFiltersContainer: {
    margin: '20px 0px',
    alignItems: 'center',
  },
  zoneFilterBtn: {
    textTransform: 'capitalize',
    fontWeight: 700,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 30,
      paddingRight: 30,
    },
    whiteSpace: 'nowrap',
  },
  zoneDetailsWrapper: {
    backgroundColor: '#F3F6F9',
    padding: 15,
    borderRadius: 5,
    height: '100%',
  },
  outlinedBtnZone1: {
    borderColor: theme.palette.jobZone.colors.zone1,
    borderWidth: 2,
    color: theme.palette.jobZone.colors.zone1,
  },
  outlinedBtnZone2: {
    borderColor: theme.palette.jobZone.colors.zone2,
    borderWidth: 2,
    color: theme.palette.jobZone.colors.zone2,
  },
  outlinedBtnZone3: {
    borderColor: theme.palette.jobZone.colors.zone3,
    borderWidth: 2,
    color: theme.palette.jobZone.colors.zone3,
  },
  outlinedBtnZone4: {
    borderColor: theme.palette.jobZone.colors.zone4,
    borderWidth: 2,
    color: theme.palette.jobZone.colors.zone4,
  },
  outlinedBtnZone5: {
    borderColor: theme.palette.jobZone.colors.zone5,
    borderWidth: 2,
    color: theme.palette.jobZone.colors.zone5,
  },
  filledBtnZone1: {
    borderColor: theme.palette.jobZone.colors.zone1,
    borderWidth: 2,
    backgroundColor: theme.palette.jobZone.colors.zone1,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.jobZone.colors.zone1,
    },
  },
  filledBtnZone2: {
    borderColor: theme.palette.jobZone.colors.zone2,
    borderWidth: 2,
    backgroundColor: theme.palette.jobZone.colors.zone2,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.jobZone.colors.zone2,
    },
  },
  filledBtnZone3: {
    borderColor: theme.palette.jobZone.colors.zone3,
    borderWidth: 2,
    backgroundColor: theme.palette.jobZone.colors.zone3,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.jobZone.colors.zone3,
    },
  },
  filledBtnZone4: {
    borderColor: theme.palette.jobZone.colors.zone4,
    borderWidth: 2,
    backgroundColor: theme.palette.jobZone.colors.zone4,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.jobZone.colors.zone4,
    },
  },
  filledBtnZone5: {
    borderColor: theme.palette.jobZone.colors.zone5,
    borderWidth: 2,
    backgroundColor: theme.palette.jobZone.colors.zone5,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.jobZone.colors.zone5,
    },
  },
  otherFilters: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 10px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  otherFiltersContent: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      whiteSpace: 'nowrap',
      alignSelf: 'flex-start',
    },
    width: '100%',
  },
  totalCount: {
    ...theme.typography.t14,
    fontWeight: 700,
    color: theme.palette.greyScale[110],
    marginRight: 16,
  },
  viewItem: {
    width: 'initial',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: 18,
    color: theme.palette.greyScale[100],
    borderRadius: 4,
    '&:hover': {
      color: theme.palette.text.primary,
    },
    '& svg': {
      marginRight: 5,
    },
  },
  activeView: {
    color: theme.palette.text.primary,
    backgroundColor: '#E2E9EF',
  },
  list: {
    display: 'flex',
    alignItems: 'stretch',
    flex: '1 0 0',
    width: '100%',
  },
  zoneItem: {
    ...theme.typography.t16,
    //width: 'initial',
    textTransform: 'capitalize',
    fontWeight: 400,
    color: theme.palette.greyScale[110],
    display: 'flex',
    padding: 12,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'stretch',
    borderBottom: `1px solid ${theme.palette.greyScale[20]}`,
    flex: 1,
  },
  activeZone: {
    color: theme.palette.cerulean.cerulean20,
    backgroundColor: 'white',
    borderBottom: `2px solid ${theme.palette.cerulean.cerulean20}`,
    fontWeight: 700,
    borderRadius: '12px 12px 0 0',
  },
  zoneInfoBtn: {
    ...theme.typography.t16,
    width: 'initial',
    textTransform: 'capitalize',
    fontWeight: 700,
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.aqua.extraDeep,
    '& svg': {
      marginRight: 8,
      height: 16,
      width: 16,
    },
  },
  middleFilter: {
    ...theme.typography.t16,
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.palette.navy.main,
    margin: '24px 0',
  },
  middleDisableFilter: {
    ...theme.typography.t16,
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.navy.main,
    margin: '24px 0',
  },
  fitFilter: {
    ...theme.typography.t16,
    fontWeight: 700,
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    border: `1px solid ${theme.palette.greyScale.grey70}`,
    borderRadius: 100,
    minWidth: 210,
    width: 'fit-content',
    marginRight: 24,
    '& p': {
      ...theme.typography.t16,
      fontWeight: 700,
      textTransform: 'capitalize',
    },
    '& svg': {
      height: 16,
      width: 16,
      color: 'white',
      marginRight: 8,
    },
  },
  filterBox: {
    padding: '10px 12px',
    height: 48,
    backgroundColor: 'white',
  },
  allFilter: {
    borderRadius: '0 100px 100px 0',
  },
  bestFitFilter: {
    borderRadius: '100px 0 0 100px',
  },
  selected: {
    backgroundColor: theme.palette.navy.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.navy.main,
    },
    '& div': {
      display: 'flex',
    },
  },
});
