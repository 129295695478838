import React from 'react';
import PropTypes from 'prop-types';
import { Box, Collapse } from '@material-ui/core';
import useExpandCollapseSections from './useExpandCollapseSections';
import { withStyles } from '@material-ui/core/styles';
import styles from './expand-collapse-sections.styles';

const ExpandCollapseSections = React.forwardRef(({ classes, topSection, bottomSection, defaultExpanded }, ref) => {
  const { isTopExpanded } = useExpandCollapseSections(defaultExpanded, ref);

  return (
    <Box className={classes.root}>
      <Box className={classes.section}>
        <Collapse
          in={isTopExpanded}
          classes={{
            wrapperInner: classes.sectionWrapper,
          }}
        >
          <Box className={classes.topSection}>{topSection.expanded}</Box>
          <Box className={classes.bottomSection}>{bottomSection.collapsed}</Box>
        </Collapse>

        <Collapse
          in={!isTopExpanded}
          classes={{
            wrapperInner: classes.sectionWrapper,
          }}
        >
          <Box className={classes.topSection}>{topSection.collapsed}</Box>
          <Box className={classes.bottomSection}>{bottomSection.expanded}</Box>
        </Collapse>
      </Box>
    </Box>
  );
});

ExpandCollapseSections.propTypes = {
  classes: PropTypes.object.isRequired,
  topSection: PropTypes.shape({
    expanded: PropTypes.node.isRequired,
    collapsed: PropTypes.node.isRequired,
  }).isRequired,
  bottomSection: PropTypes.shape({
    expanded: PropTypes.node.isRequired,
    collapsed: PropTypes.node.isRequired,
  }).isRequired,
  defaultExpanded: PropTypes.oneOf(['top', 'bottom']).isRequired,
};

export default withStyles(styles)(ExpandCollapseSections);
