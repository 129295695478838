/**
 * @fileoverview Service to handle partner system logout functionality
 * This service manages the caching and execution of partner system logout URLs
 * through iframes and popup windows.
 */

import { showHideLoader, store } from '../../../../core';
import { Constant, UserService } from '../../../../shared';

/**
 * Key used to store logout configuration in localStorage
 * @constant {string}
 */
const LOGOUT_CACHE_CONFIG_KEY = 'partnerLogoutConfig';

/**
 * Window specifications for popup logout windows
 * @constant {string}
 */
const POPUP_WINDOW_SPECS = 'width=20px, height=20px';

/**
 * Extracts logout configuration from the provided data
 * @param {Object} data - The data object containing logout configuration
 * @param {Object} [data.logoutCacheConfig] - Logout cache configuration object
 * @param {string} [data.logoutCacheConfig.logoutUrl] - URL to be used for logout
 * @param {string} [data.logoutCacheConfig.logoutFrameType] - Type of frame to use ('_blank' or '_self')
 * @returns {Object|undefined} Extracted configuration or undefined if invalid
 */
const extractLogoutCacheConfig = (data) => {
  let cacheConfig;

  if (typeof data === 'object' && data.logoutCacheConfig?.logoutUrl) {
    cacheConfig = {
      url: data.logoutCacheConfig.logoutUrl,
      frameType: data.logoutCacheConfig.logoutFrameType || '_blank',
    };
  }

  return cacheConfig;
};

/**
 * Stores logout configuration in localStorage
 * Appends new config to existing configs if present
 * @param {Object} config - The logout configuration to store
 * @param {string} config.url - The logout URL
 * @param {string} config.frameType - The frame type for logout
 */
const setLogoutCacheConfig = (config) => {
  try {
    let value = JSON.stringify([config]);
    const cacheConfig = getLogoutCacheConfig();

    if (cacheConfig?.length > 0 && !cacheConfig.some((c) => c.url === config.url)) {
      cacheConfig.push(config);
      value = JSON.stringify(cacheConfig);
    }

    localStorage.setItem(LOGOUT_CACHE_CONFIG_KEY, value);
  } catch (error) {
    console.error('Error while setting cache config', error);
  }
};

/**
 * Retrieves logout configuration from localStorage
 * @returns {Array<Object>|undefined} Array of logout configurations or undefined if not found
 */
const getLogoutCacheConfig = () => {
  let cacheConfig;

  try {
    const value = localStorage.getItem(LOGOUT_CACHE_CONFIG_KEY);
    if (value) {
      cacheConfig = JSON.parse(value);
    }
  } catch (error) {
    console.error('Error while getting cache config', error);
  }

  return cacheConfig;
};

/**
 * Removes logout configuration from localStorage
 */
const clearLogoutCacheConfig = () => {
  try {
    localStorage.removeItem(LOGOUT_CACHE_CONFIG_KEY);
  } catch (error) {
    console.error('Error while clearing cache config', error);
  }
};

/**
 * Extracts and persists logout configuration from provided data
 * @param {Object} data - Data containing logout configuration
 */
export const persistLogoutCacheConfig = (data) => {
  const cacheConfig = extractLogoutCacheConfig(data);

  if (cacheConfig) {
    setLogoutCacheConfig(cacheConfig);
  }
};

/**
 * Launches logout URLs either in popup windows or hidden iframes
 * @param {Array<Object>} data - Array of logout configurations
 * @param {string} data[].logoutUrl - URL to launch for logout
 * @param {string} data[].logoutFrameType - Type of frame to use ('_blank' or '_self')
 * @returns {Promise<void[]>} Promise that resolves when all logout operations complete
 */
const launchLogoutUrls = (data) => {
  return Promise.all(
    data.map(({ url, frameType }) => {
      return new Promise((resolve) => {
        switch (frameType) {
          case '_blank':
            let partnerLogoutWindow = window.open(url, '_blank', POPUP_WINDOW_SPECS);
            if (partnerLogoutWindow) {
              setTimeout(() => {
                partnerLogoutWindow.close();
                resolve();
              }, Constant.PARTNER_LOGOUT_WINDOW_TIME);
            } else {
              console.warn('Popup blocked by browser.');
              resolve();
            }
            break;

          case '_self':
          default:
            const iframe = document.createElement('iframe');
            iframe.setAttribute('src', url);
            iframe.style.display = 'none';
            document.body.appendChild(iframe);

            setTimeout(() => {
              document.body.removeChild(iframe);
              resolve();
            }, Constant.PARTNER_LOGOUT_WINDOW_TIME);
        }
      });
    })
  );
};

/**
 * Main function to handle complete partner system logout process
 * 1. Retrieves cached logout configurations
 * 2. Launches all logout URLs if configurations exist
 * 3. Clears the cache after successful logout
 * 4. Calls UserService to logout from partner systems using the old method of calling saml-logout endpoint as fallback
 * @param {boolean} [useFallback=true] - Flag to enable/disable fallback logout method. Default is true
 * @returns {Promise<void>} Promise that resolves when all logout operations complete
 */
export const logoutFromPartnerSystems = async (useFallback = true) => {
  try {
    const cacheConfig = getLogoutCacheConfig();

    if (cacheConfig?.length > 0) {
      /**
       * Show loader while logging out from partner systems.
       * This is to prevent user from interacting with the app while logout is in progress.
       * No need to hide loader as the app will be redirected to login page after logout.
       */
      store.dispatch(showHideLoader({ isLoading: true }));
      await launchLogoutUrls(cacheConfig);
      clearLogoutCacheConfig();
    } else if (useFallback) {
      await new UserService().logoutOnPartnerSystems();
    }
  } catch (error) {
    console.error('Error while logging out from partner systems', error);
  }
};
