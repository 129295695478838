import { useRef } from 'react';

const useProgramSelectionContainerHook = ({ unleashProps, currentStep }) => {
  const expandCollapseSectionsRef = useRef();
  const isFeatureFlagEnabled = unleashProps?.isFlagEnabled();

  const expandTopSection = () => {
    expandCollapseSectionsRef.current.expandTopSection();
  };

  const expandBottomSection = () => {
    expandCollapseSectionsRef.current.expandBottomSection();
  };

  const isNotFirstProgram = !currentStep.isFirstTime;

  return {
    expandCollapseSectionsRef,
    isFeatureFlagEnabled,
    expandTopSection,
    expandBottomSection,
    isNotFirstProgram,
  };
};

export default useProgramSelectionContainerHook;
