import { useEffect, useState } from 'react';
import { markAllAnnouncementRead, markAllMessagesRead } from '../../services/staff-messaging.service';
import { useIntl } from 'react-intl';

export const useMarkReadHook = (props) => {
  const {
    isMessageActive,
    isAnnouncementActive,
    messageCount,
    announcementCount,
    markAllMessagesReadLocally,
    markAllMessagesAnnouncementLocally,
  } = props;

  const [isDisabled, setDisabled] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState('error');
  const intl = useIntl();

  useEffect(() => {
    updateDisabled();
  }, [isMessageActive, isAnnouncementActive, messageCount, announcementCount]);

  const updateDisabled = () => {
    setDisabled((isMessageActive && messageCount === 0) || (isAnnouncementActive && announcementCount === 0));
  };

  const updateAllMessages = () => {
    if (isMessageActive) {
      markAllMessagesReadLocally();
    } else if (isAnnouncementActive) {
      markAllMessagesAnnouncementLocally();
    }
  };

  const showMarkReadFailedMessage = () => {
    setSnackbarMessage(intl.formatMessage({ id: 'markAllReadError' }));
    setIsSnackbarOpen(true);
    setSnackbarType('error');
    setDisabled(false);
    setLoading(false);
  };

  /**
   * @desc Handle to mark all messages as read
   * @return {void}
   */
  const markAllReadHandler = async () => {
    try {
      setLoading(true);
      const response = isMessageActive ? await markAllMessagesRead() : await markAllAnnouncementRead();
      setLoading(false);
      if (!response) {
        showMarkReadFailedMessage();
        return;
      }
      updateAllMessages();
    } catch (e) {
      showMarkReadFailedMessage();
      console.error(e);
    }
  };

  const handleSnackbarToggle = () => {
    setIsSnackbarOpen(!isSnackbarOpen);
  };

  return {
    isLoading,
    isDisabled,
    snackbarMessage,
    snackbarType,
    isSnackbarOpen,
    handleSnackbarToggle,
    markAllReadHandler,
  };
};
