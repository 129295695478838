export default (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 8,
    backgroundColor: theme.palette.background.paper,
    height: 'calc(100dvh - 255px)',
    overflowY: 'scroll',
    overflowX: 'hidden',
    '-ms-overflow-style': 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  header: {
    padding: '16px 0px',
  },
  sectionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 24px 24px',
    gap: 24,
  },
});
