import { useEffect, useRef } from 'react';
import { fetchSummary } from '../../services/career-compass.service';

/**
 * Custom hook that fetches the career compass summary if it hasn't been fetched yet.
 *
 * @param {object} summary - The career compass summary object.
 * @param {boolean} isSummaryLoading - The loading state of the career compass summary.
 * @param {string} selectedLanguage - The selected language.
 */
const useCareerCompassSummaryHook = (summary, isSummaryLoading, selectedLanguage) => {
  const previousLanguage = useRef(selectedLanguage);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const isInitialFetch = !summary && !isSummaryLoading;
        const isLanguageChange = previousLanguage.current !== selectedLanguage && !isSummaryLoading;

        if (isInitialFetch || isLanguageChange) {
          await fetchSummary();
        }

        previousLanguage.current = selectedLanguage;
      } catch (error) {
        console.error('Error fetching career compass summary', error);
      }
    };

    fetchData();
  }, [summary, isSummaryLoading, selectedLanguage]);
};

export default useCareerCompassSummaryHook;
