export default (theme) => ({
  root: {
    height: '100%',
    width: '100%',
    gap: 24,
  },
  section: {
    flex: 1,
  },
  sectionWrapper: {
    gap: 24,
    display: 'flex',
    flexDirection: 'column',
  },
  topSection: {},
  bottomSection: {},
});
