import React from 'react';
import PropTypes from 'prop-types';
import { Box, withStyles } from '@material-ui/core';
import styles from './career-compass.styles';
import { Permission } from '../../../../../core';
import { useDocumentTitleHook } from '../../../../shared';
import useCareerCompassHook from './useCareerCompassHook';
import { IntroductionGuide } from '../../components';
import { connect } from 'react-redux';
import ProgramSelectionContainer from '../program-selection/program-selection.container';
import useCareerCompassSummaryHook from '../../components/summary/useCareerCompassSummaryHook';
import ProgramDetailsContainer from '../program-details/program-details.container';

/**
 * Component that renders the Career Compass
 *
 * @component
 * @param {object} props - The component props
 * @returns {JSX.Element}
 */
const CareerCompass = ({ classes, history, currentStep, summary, isSummaryLoading, selectedProgram, selectedLocale, ...props }) => {
  useDocumentTitleHook('page.title.career-compass');
  const { programId, showIntroductionGuide, showProgramSelection, showProgramDetails, handleCurrentStepUpdate } =
    useCareerCompassHook(currentStep);
  useCareerCompassSummaryHook(summary, isSummaryLoading, selectedLocale);

  return (
    <Permission {...props}>
      <Box className={classes.root} data-testid="career-compass-root">
        {showIntroductionGuide() && <IntroductionGuide history={history} onContinue={handleCurrentStepUpdate} />}
        {showProgramSelection() && (
          <ProgramSelectionContainer
            currentStep={currentStep}
            summary={summary}
            selectedLocale={selectedLocale}
            handleCurrentStepUpdate={handleCurrentStepUpdate}
          />
        )}
        {showProgramDetails() && (
          <ProgramDetailsContainer
            programId={programId}
            currentStep={currentStep}
            summary={summary}
            selectedLocale={selectedLocale}
            handleCurrentStepUpdate={handleCurrentStepUpdate}
            selectedProgram={selectedProgram}
          />
        )}
      </Box>
    </Permission>
  );
};

CareerCompass.propTypes = {
  /**
   * The classes object to use for styling that will be injected by withStyles.
   */
  classes: PropTypes.object.isRequired,
  /**
   * The history object to use for navigation.
   */
  history: PropTypes.object.isRequired,
  /**
   * The current step object.
   */
  currentStep: PropTypes.object,
  /**
   * The summary object.
   */
  summary: PropTypes.shape({
    programsCompleted: PropTypes.number,
    summaryItems: PropTypes.arrayOf(
      PropTypes.shape({
        category: PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
        }),
        programs: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            title: PropTypes.string,
            isCompleted: PropTypes.bool,
            isStarted: PropTypes.bool,
          })
        ),
      })
    ),
  }),
  /**
   * The loading state of the summary object.
   */
  isSummaryLoading: PropTypes.bool,
  /**
   * The selected program object
   */
  selectedProgram: PropTypes.object,
  /**
   * The selected locale.
   */
  selectedLocale: PropTypes.string.isRequired,
};

const mapStateToProps = ({ locale, careerCompass }) => {
  return {
    currentStep: careerCompass.currentStep,
    summary: careerCompass.summary,
    isSummaryLoading: careerCompass.isSummaryLoading,
    selectedProgram: careerCompass.selectedProgram,
    selectedLocale: locale.locale,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CareerCompass));
