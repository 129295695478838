import { useState, useEffect, useRef, useCallback } from 'react';
import { submitVoiceSample, ContactRequestConstants } from '../../../../services';
import RecordRTC from 'recordrtc';
import { Constant } from '../../../../../../shared';

const useVoiceRecordingHook = (setProfileStatus) => {
  const [recordingCountDown, setRecordingCountDown] = useState(5);
  const [isRecordingCountDownStarted, setIsRecordingCountDownStarted] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [recordingDone, setRecordingDone] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [recordingSubmitting, setRecordingSubmitting] = useState(false);

  const videoRef = useRef(null);
  const mediaStreamRef = useRef(null);
  const videoBlobRef = useRef(null);
  const audioBlobRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const audioRecorderRef = useRef(null);

  const handleStartRecording = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      mediaStreamRef.current = stream;

      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      }

      const mediaRecorder = new MediaRecorder(stream, { mimeType: 'video/webm' });
      const videoChunks = [];
      mediaRecorder.ondataavailable = (event) => videoChunks.push(event.data);
      mediaRecorder.onstop = () => {
        const videoBlob = new Blob(videoChunks, { type: 'video/webm' });
        videoBlobRef.current = videoBlob; // Save video blob
      };
      mediaRecorder.start();
      mediaRecorderRef.current = mediaRecorder;

      const audioRecorder = new RecordRTC(stream, {
        type: 'audio',
        mimeType: 'audio/wav',
        recorderType: RecordRTC.StereoAudioRecorder,
        numberOfAudioChannels: 1, // Ensure mono audio
        desiredSampRate: 16000, // Set the desired sample rate
      });
      audioRecorder.startRecording();
      audioRecorderRef.current = audioRecorder;

      setIsRecording(true);
    } catch (err) {
      console.error('Error accessing camera/microphone:', err);
    }
  }, []);

  const handleStopRecording = useCallback(async () => {
    const mediaRecorder = mediaRecorderRef.current;
    if (mediaRecorder) {
      mediaRecorder.stop();
    }

    const audioRecorder = audioRecorderRef.current;
    if (audioRecorder) {
      await audioRecorder.stopRecording(() => {
        const audioBlob = audioRecorder.getBlob();
        audioBlobRef.current = audioBlob;

        revokeMediaAccess();
      });
    }
  }, []);

  const revokeMediaAccess = () => {
    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach((track) => track.stop());
      mediaStreamRef.current = null;
    }
  };

  const reRecord = () => {
    setIsRecording(false);
    setRecordingDone(false);
    setRecordingCountDown(5);
    setRecordingTime(0);
    setIsRecordingCountDownStarted(true);
    setRecordingSubmitting(false);
  };

  const cancelRecording = () => {
    setIsRecording(false);
    setRecordingTime(0);
    setRecordingCountDown(5);
    handleStopRecording();
  };

  const startRecordingCountDown = () => {
    if (recordingCountDown === null || recordingCountDown === 0) return;
    setIsRecordingCountDownStarted(true);
    setRecordingCountDown(5);
  };

  const submitRecording = async () => {
    if (!audioBlobRef.current || !videoBlobRef.current) {
      console.error('No video/audio data to submit');
      return;
    }

    setRecordingSubmitting(true);
    setRecordingDone(false);
    const formData = new FormData();
    formData.append('videoFile', videoBlobRef.current, 'video.webm');
    formData.append('audioFile', audioBlobRef.current, 'audio.wav');

    try {
      const response = await submitVoiceSample(formData);
      if (response) {
        console.log('Video and audio submitted successfully:', response);
        setProfileStatus(Constant.VOICE_PROFILE_ID_STATUS.PROFILE_CREATED);
        setRecordingSubmitting(false);
      }
    } catch (error) {
      console.error('Error submitting the recording:', error);
    }
  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  const playAudioTone = useCallback(() => {
    const audio = new Audio('/assets/misc/beep.WAV');
    audio.play();
  }, []);

  useEffect(() => {
    if (recordingCountDown === 0) {
      playAudioTone();
      setIsRecordingCountDownStarted(false);
      setIsRecording(true);
      setRecordingCountDown(null);
      handleStartRecording();
    }

    if (isRecordingCountDownStarted && recordingCountDown > 0) {
      const timer = setTimeout(() => {
        setRecordingCountDown(recordingCountDown - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [recordingCountDown, isRecordingCountDownStarted, handleStartRecording, playAudioTone]);

  useEffect(() => {
    if (isRecording && recordingTime < ContactRequestConstants.VOICE_ID_REGISTRATION_RECORDING_TIME_LIMIT) {
      const timer = setTimeout(() => {
        setRecordingTime(recordingTime + 1);
      }, 1000);
      return () => clearTimeout(timer);
    }

    if (recordingTime === ContactRequestConstants.VOICE_ID_REGISTRATION_RECORDING_TIME_LIMIT) {
      handleStopRecording();
      setIsRecording(false);
      setRecordingDone(true);
    }
  }, [recordingTime, isRecording, handleStopRecording]);

  return {
    recordingCountDown,
    isRecordingCountDownStarted,
    startRecordingCountDown, // Restored function
    recordingTime,
    formatTime,
    isRecording,
    cancelRecording,
    recordingDone,
    submitRecording,
    recordingSubmitting,
    reRecord,
    videoRef,
  };
};

export default useVoiceRecordingHook;
