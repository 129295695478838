import React, { useEffect, useRef, useState } from 'react';
import {
  withStyles,
  Box,
  Typography,
  Grid,
  Button,
  FormControl,
  OutlinedInput,
  InputAdornment,
  Select,
  List,
  ListItem,
  MenuItem,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import InfoIcon from '@material-ui/icons/Info';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';

import styles from './filters-area.style';
import { FormattedMessage, useIntl } from 'react-intl';
import { Constant } from '../../../../../shared/services';
import ZoneDetails from '../../../../../content/careers/components/zone-details/zone-details';
import { CAREER_DIRECTORY_FILTERS } from '../../../../../content/careers/containers/career-directory/constants';

const FiltersArea = (props) => {
  const {
    classes,
    selectedZone,
    onZoneChange,
    onSearch,
    totalCount,
    mainFilter,
    onGroupChange,
    selectedGroup,
    categories,
    selectedCategory,
    onCategoryChange,
    alphabeticalGroups,
    onClearFilters,
    handleFilterChange,
    ipTaken,
  } = props;
  const [searchValue, setSearchValue] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const [selectedCategoryValue, setSelectedCategoryValue] = useState('');
  const categoryFilterRef = useRef(null);
  const intl = useIntl();
  const minCharForSearch = 3;

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    const selected = categories.filter((c) => c.code === selectedCategory);
    if (selected.length > 0) {
      setSelectedCategoryValue(selected[0]);
    }
  }, [selectedCategory, categories]);

  return (
    <Box>
      <Box className={classes.mainFilters}>
        <ZoneDetails
          id="zone-details-dialog"
          isOpen={showDialog}
          selectedZone={selectedZone}
          onClose={() => {
            setShowDialog(false);
          }}
        />

        <Box className={classes.content}>
          <Grid className={classes.filterArea} sm={12}>
            {/* BEGIN: SEARCH BAR */}
            <Box className={classes.centerContainer}>
              <Box width="100%">
                <FormControl variant="outlined" fullWidth className={classes.searchBarWrapper}>
                  <OutlinedInput
                    id="search-careers"
                    autoFocus={searchValue !== ''}
                    placeholder={intl.formatMessage({ id: 'career-filters.title' })}
                    value={searchValue}
                    onChange={handleSearchChange}
                    labelWidth={0}
                    fullWidth
                    className={classes.searchBar}
                    classes={{
                      notchedOutline: classes.noBorder,
                    }}
                    inputProps={{
                      id: 'search-careers-input',
                      'aria-label': intl.formatMessage({ id: 'searchCareers' }),
                    }}
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon fontSize="medium" color="primary" />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Box>
              <Box>
                <Button
                  id="search-careers-button"
                  className={classes.searchBtn}
                  color="primary"
                  variant="contained"
                  size="large"
                  onClick={() => onSearch(searchValue)}
                  disabled={searchValue.length < minCharForSearch}
                  tracking-type={Constant.TRACKING_TYPES.CAREER_DIRECTORY}
                  tracking-id="search-careers"
                  aria-label={intl.formatMessage({ id: 'search' })}
                >
                  <FormattedMessage id="search" />
                </Button>
              </Box>
            </Box>
            {/* END: SEARCH BAR */}

            <Box className={classes.marginTop20}>
              <Box ref={categoryFilterRef} className={classes.centerContainer}>
                {/* BEGIN: CATEGORY FILTER */}
                <Box className={classes.categoryFilter}>
                  <FormControl>
                    <Select
                      id="category-filter"
                      value={selectedCategoryValue}
                      onChange={(e) => onCategoryChange(e.target.value.code)}
                      className={classes.categorySelect}
                      classes={{
                        selectMenu: classes.selectOption,
                        root: classes.selectRoot,
                      }}
                      renderValue={(value) => (
                        <Typography className={classes.categorySelectLabel}>
                          {value ? value.title : 'Filter by Category'}
                        </Typography>
                      )}
                      MenuProps={{
                        classes: {
                          paper: classes.selectMenu,
                        },
                      }}
                      onFocus={() => {
                        categoryFilterRef.current.focus();
                      }}
                      displayEmpty
                      IconComponent={ExpandMoreIcon}
                      disableUnderline
                      inputProps={{ 'aria-label': intl.formatMessage({ id: 'selectCategory' }) }}
                    >
                      {categories.map((category, index) => (
                        <MenuItem key={index} value={category} id={'option' + index}>
                          {category.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box>
                  <Button
                    id="clear-filters"
                    color="secondary"
                    size="large"
                    onClick={() => {
                      onClearFilters();
                      setSearchValue('');
                    }}
                    className={classes.clearFiltersBtn}
                    aria-label={intl.formatMessage({ id: 'clearAllFilters' })}
                    variant="outlined"
                  >
                    <FormattedMessage id="clearFilters" />
                  </Button>
                </Box>
                {/* END: CATEGORY FILTER */}
              </Box>
            </Box>
          </Grid>
        </Box>
      </Box>
      <Box className={[ipTaken ? classes.middleFilter : classes.middleDisableFilter].join(' ')}>
        {ipTaken && (
          <Box className={classes.fitFilter}>
            <Button
              id="best-fit-btn"
              onClick={() => {
                handleFilterChange(CAREER_DIRECTORY_FILTERS.BEST_FIT);
              }}
              className={[
                classes.filterBox,
                classes.bestFitFilter,
                mainFilter === CAREER_DIRECTORY_FILTERS.BEST_FIT ? classes.selected : '',
              ].join(' ')}
              aria-label={intl.formatMessage({ id: 'showBestFitCareers' })}
            >
              {mainFilter === CAREER_DIRECTORY_FILTERS.BEST_FIT ? (
                <Box className={classes.icon}>
                  <CheckIcon />
                </Box>
              ) : (
                <Box className={classes.radioBtn} />
              )}
              <Typography className={classes.filterText}>
                <FormattedMessage id="bestFit" />
              </Typography>
            </Button>
            <Button
              id="show-all-btn"
              onClick={() => {
                handleFilterChange(CAREER_DIRECTORY_FILTERS.ALL);
              }}
              className={[
                classes.filterBox,
                classes.allFilter,
                mainFilter === CAREER_DIRECTORY_FILTERS.ALL ? classes.selected : '',
              ].join(' ')}
              aria-label={intl.formatMessage({ id: 'showAllCareers' })}
            >
              {mainFilter === CAREER_DIRECTORY_FILTERS.ALL ? (
                <Box className={classes.icon}>
                  <CheckIcon />
                </Box>
              ) : (
                <Box className={classes.radioBtn} />
              )}
              <Typography className={classes.filterText}>
                <FormattedMessage id="showAll" />
              </Typography>
            </Button>
          </Box>
        )}
        <Typography id="careers-intro-description" className={classes.description}>
          <FormattedMessage id={ipTaken ? 'interest-profiler-results.description' : 'careers-intro.description'} />
        </Typography>
      </Box>

      <Box id="other-filters" className={classes.otherFilters}>
        <Box className={classes.otherFiltersContent}>
          <Typography id="total-count" className={classes.totalCount}>
            <FormattedMessage id="resultsInBrackets" values={{ count: totalCount }} />
          </Typography>
          <List id="filter-list" className={classes.list}>
            {mainFilter === CAREER_DIRECTORY_FILTERS.BEST_FIT
              ? Constant.CAREER_ZONES.map((zone) => (
                  <ListItem
                    key={zone.value}
                    button
                    onClick={() => {
                      onZoneChange(zone.value);
                    }}
                    className={[classes.zoneItem, selectedZone === zone.value ? classes.activeZone : ''].join(' ')}
                    aria-label={intl.formatMessage({ id: 'filertByZone' }, { zone: zone.value })}
                  >
                    <FormattedMessage id="zone" /> {zone.value}
                  </ListItem>
                ))
              : alphabeticalGroups.map((group) => (
                  <ListItem
                    key={group.group}
                    button
                    onClick={() => {
                      onGroupChange(group.group);
                    }}
                    className={[classes.zoneItem, selectedGroup === group.group ? classes.activeZone : ''].join(' ')}
                    aria-label={intl.formatMessage({ id: 'filertByAlphabeticalGroup' }, { group: group.group })}
                  >
                    {group.group}
                  </ListItem>
                ))}
            <ListItem
              button
              id="career-zone-info-btn"
              onClick={() => {
                setShowDialog(true);
              }}
              className={classes.zoneInfoBtn}
              aria-label={intl.formatMessage({ id: 'zoneInfo' })}
            >
              <InfoIcon />
              <FormattedMessage id={mainFilter === CAREER_DIRECTORY_FILTERS.BEST_FIT ? 'info' : 'zoneInfo'} />
            </ListItem>
          </List>
        </Box>
      </Box>
    </Box>
  );
};

export default withStyles(styles)(FiltersArea);
