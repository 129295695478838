import { IntroductionGuide } from '../components';
import withCareerCompassAuth from './career-compass/withCareerCompassAuth';
import { CAREER_COMPASS_CONSTANTS } from '../services/career-compass.service';

export { default as CareerCompass } from './career-compass/career-compass';
export { default as CareerCompassProgramSelection } from './program-selection/program-selection.container';
export { default as CareerCompassProgramDetails } from './program-details/program-details.container';

export const CareerCompassIntroduction = withCareerCompassAuth(
  IntroductionGuide,
  CAREER_COMPASS_CONSTANTS.PAGES.INTRODUCTION_GUIDE
);
