export default (theme) => ({
  root: {
    width: '100%',
    paddingLeft: 101,
  },
  carousel: {
    width: '100%',
    padding: '24px 0px',
  },
  carouselItem: {
    display: 'flex',
    padding: '0px 12px',
  },
  slide: {
    borderRadius: 8,
    width: '100%',
    height: 'calc(100dvh - 124px)',
    backgroundColor: theme.palette.primary.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflowY: 'scroll',
    scrollBarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  content: {
    padding: '64px 24px 32px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    gap: 24,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 128,
    },
  },
  title: {
    background: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), ${theme.palette.aqua.extraDeep}`,
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    color: 'transparent',
  },
  description: {
    color: theme.palette.aqua.extraDeep,
    maxWidth: 480,
  },
  controlsArea: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8,
    paddingTop: 12,
  },
  dotsContainer: {
    display: 'flex',
    paddingTop: 12,
    gap: 12,
  },
  dotContainer: {
    width: 40,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dot: {
    width: 24,
    minWidth: 24,
    height: 24,
    borderRadius: '100%',
    opacity: 1,
    transition: 'background .5s',
    background: theme.palette.greyScale[50],
  },
  activeDot: {
    background: theme.palette.aqua.deep,
    '&:hover': {
      background: theme.palette.aqua.deep,
    },
  },
  previousButton: {
    borderRadius: 9999,
    textTransform: 'capitalize',
    borderColor: theme.palette.aqua.deep,
    background: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), ${theme.palette.aqua.deep}`,
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    color: 'transparent',
    '&:hover': {
      borderColor: theme.palette.aqua.deep,
      color: theme.palette.aqua.deep,
    },
  },
  nextButton: {
    borderRadius: 9999,
    textTransform: 'capitalize',
    background: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), ${theme.palette.aqua.deep}`,
    '&:hover': {
      background: theme.palette.aqua.deep,
    },
  },
  imageContainer: {
    minWidth: 395,
    minHeight: 336,
    width: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0px 0px 8px 8px',
      minWidth: 675,
      minHeight: 575,
    },
  },
  image: {
    position: 'absolute',
    bottom: 15,
    maxWidth: 395,
    maxHeight: 336,
    width: '100%',
    height: 'auto',
    borderRadius: 5000,
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0px 0px 8px 8px',
      maxWidth: 675,
      maxHeight: 575,
      bottom: 0,
    },
  },
});
