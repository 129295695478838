export default (theme) => ({
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.primary.sub,
    color: 'white',
    padding: 25,
  },
  title: {
    ...theme.typography.t30,
    textTransform: 'capitalize',
    fontWeight: 700,
  },
  closeDialogBtn: {
    color: 'white',
  },
  languagesList: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 0',
    gap: 20,
  },
});
