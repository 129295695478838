import React from 'react';
import PropTypes from 'prop-types';
import { Box, withStyles } from '@material-ui/core';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import styles from './total-progress.styles';
import { Typography } from '@orijinworks/frontend-commons';

const TotalProgress = ({ classes, progressHeading, programsCompleted, isExpanded }) => {
  return (
    <Box data-testid="root-element" className={`${classes.root} ${isExpanded ? classes.rootExpanded : ''}`}>
      <WorkspacePremiumIcon className={classes.icon} />
      <Box className={classes.progressInfo}>
        <Typography
          data-testid="progress-heading"
          variant={isExpanded ? 'overlineLarge' : 'overlineSmall'}
          className={classes.progressHeading}
        >
          {progressHeading}
        </Typography>
        <Typography data-testid="programs-completed" className={classes.programsCompleted}>
          {programsCompleted}
        </Typography>
      </Box>
    </Box>
  );
};

TotalProgress.propTypes = {
  classes: PropTypes.object.isRequired,
  progressHeading: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  programsCompleted: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  isExpanded: PropTypes.bool.isRequired,
};

export default withStyles(styles)(TotalProgress);
