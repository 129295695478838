import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, withStyles } from '@material-ui/core';
import styles from './review-images.styles';
import { CheckCircleIcon } from '@orijinworks/frontend-commons';
import { FormattedMessage } from 'react-intl';
import { PillButton } from '../../../../../../../frontend-commons';

/**
 * Component to render the biometric images for review.
 *
 * @param {object} props - The props of the component.
 * @returns {JSX.Element}
 */
const ReviewImages = ({ classes, onContinue }) => {
  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <Box className={classes.titleContainer}>
          <CheckCircleIcon fontSize="large" color="primary" />
          <Typography id="review-images-title" variant="h1" className={classes.title}>
            <FormattedMessage id="great" />!
          </Typography>
        </Box>
        <Typography id="review-images-description" className={classes.description}>
          <FormattedMessage id="biometric-authentication.registration.success" />
        </Typography>
      </Box>
      <PillButton
        id="review-images-continue-button"
        color="primary"
        variant="contained"
        size="large"
        className={classes.continueButton}
        onClick={onContinue}
      >
        <FormattedMessage id="continue" />
      </PillButton>
    </Box>
  );
};

ReviewImages.propTypes = {
  /**
   * Material-UI classes object to style the component.
   */
  classes: PropTypes.object.isRequired,
  /**
   * The onContinue function.
   */
  onContinue: PropTypes.func.isRequired,
};

export default withStyles(styles)(ReviewImages);
