import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography, useTheme, withStyles } from '@material-ui/core';
import styles from './introduction-guide.styles';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import useIntroductionGuideHook from './useIntroductionGuideHook';
import { FormattedMessage } from 'react-intl';
import { Constant } from '../../../../../shared/services';
import { useDocumentTitleHook } from '../../../../shared';

/**
 * Object containing the responsive properties for the carousel
 */
const responsiveProps = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
    partialVisibilityGutter: 30,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1250 },
    items: 1,
    partialVisibilityGutter: 30,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    partialVisibilityGutter: 30,
  },
  tablet: {
    breakpoint: { max: 1250, min: 464 },
    items: 1,
    partialVisibilityGutter: 30,
  },
};

/**
 * Component that renders the Introduction Guide
 *
 * @component
 * @param {object} props - The component props
 * @returns {JSX.Element}
 */
const IntroductionGuide = ({ classes, onComplete, ...props }) => {
  useDocumentTitleHook('page.title.career-compass.introduction-guide');
  const carouselRef = useRef(null);
  const theme = useTheme();
  const {
    SLIDES_DATA,
    currentSlide,
    isSlideActive,
    isFirstSlide,
    isLastSlide,
    beforeSlideChange,
    handleSlideChange,
    onDefer,
    onGetStarted,
  } = useIntroductionGuideHook(props, carouselRef);

  return (
    <Box className={classes.root}>
      <Carousel
        ref={carouselRef}
        className={classes.carousel}
        itemClass={classes.carouselItem}
        responsive={responsiveProps}
        arrows={false}
        beforeChange={beforeSlideChange}
      >
        {SLIDES_DATA.map((item) => (
          <Box key={item.slide} className={classes.slide} data-testid="intro-guide-slide">
            <Box className={classes.content}>
              {/* BEGIN: TEXT AREA */}
              <Typography variant="h2" className={classes.title} data-testid="intro-guide-slide-title">
                <FormattedMessage id={`career-compass.introduction-guide.slide-${item.slide}.title`} />
              </Typography>
              <Typography variant="body1" className={classes.description} data-testid="intro-guide-slide-description">
                <FormattedMessage id={`career-compass.introduction-guide.slide-${item.slide}.description`} />
              </Typography>
              {/* END: TEXT AREA */}

              <Box className={classes.controlsArea}>
                {/* BEGIN: DOTS AREA */}
                <Box className={classes.dotsContainer}>
                  {SLIDES_DATA.map((item) => (
                    <Box key={item.slide} className={classes.dotContainer}>
                      <Button
                        className={[classes.dot, isSlideActive(item.slide - 1) ? classes.activeDot : ''].join(' ')}
                        aria-label={`Go to slide ${item.slide}`}
                        onClick={() => {
                          handleSlideChange(item.slide - 1);
                        }}
                        tracking-type={Constant.TRACKING_TYPES.CAREER_COMPASS}
                        tracking-id={`go-to-intro-guide-slide-${item.slide}-using-dot`}
                        data-testid={`intro-guide-slide-${item.slide}-dot`}
                      />
                    </Box>
                  ))}
                </Box>
                {/* END: DOTS AREA */}

                <Box display="flex" justifyContent={isFirstSlide() ? 'center' : 'space-between'} width={480}>
                  {/* BEGIN: NEXT & PREVIOUS ACTIONS */}
                  {!isLastSlide() && (
                    <>
                      {!isFirstSlide() && (
                        <Button
                          variant="outlined"
                          color="primary"
                          size="large"
                          className={classes.previousButton}
                          onClick={() => {
                            handleSlideChange(currentSlide - 1);
                          }}
                          aria-label="Previous slide"
                          tracking-type={Constant.TRACKING_TYPES.CAREER_COMPASS}
                          tracking-id={`go-to-previous-slide-${item.slide - 1}`}
                          data-testid={`intro-guide-slide-${item.slide}-previous`}
                        >
                          <FormattedMessage id="previous" />
                        </Button>
                      )}

                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        className={classes.nextButton}
                        onClick={() => {
                          handleSlideChange(currentSlide + 1);
                        }}
                        aria-label="Next slide"
                        tracking-type={Constant.TRACKING_TYPES.CAREER_COMPASS}
                        tracking-id={`go-to-next-slide-${item.slide + 1}`}
                        data-testid={`intro-guide-slide-${item.slide}-next`}
                      >
                        <FormattedMessage id="next" />
                      </Button>
                    </>
                  )}
                  {/* END: NEXT & PREVIOUS ACTIONS */}

                  {/* BEGIN: LAST SLIDE ACTIONS */}
                  {isLastSlide() && (
                    <>
                      <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        className={classes.previousButton}
                        onClick={onDefer}
                        aria-label="Not right now"
                        tracking-type={Constant.TRACKING_TYPES.CAREER_COMPASS}
                        tracking-id="defer-intro-guide"
                        data-testid="intro-guide-slide-defer-button"
                      >
                        <FormattedMessage id="notRightNow" />
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        className={classes.nextButton}
                        onClick={onGetStarted}
                        aria-label="Let's get started"
                        tracking-type={Constant.TRACKING_TYPES.CAREER_COMPASS}
                        tracking-id="get-started-intro-guide"
                        data-testid="intro-guide-slide-continue-button"
                      >
                        <FormattedMessage id="letsGetStarted" />
                      </Button>
                    </>
                  )}
                  {/* END: LAST SLIDE ACTIONS */}
                </Box>
              </Box>
            </Box>

            {/* BEGIN: IMAGE */}
            <Box
              sx={{
                [theme.breakpoints.down('sm')]: {
                  background: `url(${Constant.CDN_BASE_URL}/assets/career-compass/intro-slide-${item.slide}-bg.svg) top/cover no-repeat`,
                },
                background: `url(${Constant.CDN_BASE_URL}/assets/career-compass/intro-slide-${item.slide}-bg-large.svg) top/cover no-repeat`,
              }}
              className={classes.imageContainer}
            >
              <img
                src={`${Constant.CDN_BASE_URL}/assets/career-compass/intro-slide-${item.slide}.png`}
                alt=""
                className={classes.image}
                data-testid={`intro-guide-slide-${item.slide}-image`}
              />
            </Box>
            {/* END: IMAGE */}
          </Box>
        ))}
      </Carousel>
    </Box>
  );
};

IntroductionGuide.propTypes = {
  /**
   * The classes object to use for styling that will be injected by withStyles.
   */
  classes: PropTypes.object.isRequired,
  /**
   * The history object to use for navigation.
   */
  history: PropTypes.object.isRequired,
  /**
   * The function to call when the introduction guide is completed.
   */
  onContinue: PropTypes.func.isRequired,
};

export default withStyles(styles)(IntroductionGuide);
