import { Utility } from '../../../shared/services';

/**
 * @name PENDO_TRACK_EVENTS_ENUM
 * @description Enum for Pendo track events
 * @returns {Object}
 */
const PENDO_TRACK_EVENTS_ENUM = {
  PROGRAM_ENROLLEMENT: 'Program Enrollment',
  PROGRAM_UNENROLLEMENT: 'Program Unenrollment',
  AUDIO_PLAYER_LAUNCHED: 'Audio Player Launched',
  VIDEO_PLAYER_LAUNCHED: 'Video Player Launched',
  PARTNER_SITE_LAUNCHED: 'Partner Site Launched',
  CALCULATOR_LAUNCHED: 'Calculator Launched',
  HANDBOOK_LAUNCHED: 'Handbook Launched',
  PRE_ENROLLMENT: 'Pre-Enrollment',
  AD_ACTION_CARD_CLICKED: 'Ad Action Card Clicked',
};

const getEventName = (type, { id }) => {
  switch (type) {
    case PENDO_TRACK_EVENTS_ENUM.PROGRAM_ENROLLEMENT:
      return `Program Enrolled (${id})`;
    case PENDO_TRACK_EVENTS_ENUM.PROGRAM_UNENROLLEMENT:
      return `Program Unenrolled (${id})`;
    case PENDO_TRACK_EVENTS_ENUM.AUDIO_PLAYER_LAUNCHED:
      return `${PENDO_TRACK_EVENTS_ENUM.AUDIO_PLAYER_LAUNCHED} (Service Id: ${id})`;
    case PENDO_TRACK_EVENTS_ENUM.VIDEO_PLAYER_LAUNCHED:
      return `${PENDO_TRACK_EVENTS_ENUM.VIDEO_PLAYER_LAUNCHED} (Service Id: ${id})`;
    case PENDO_TRACK_EVENTS_ENUM.PARTNER_SITE_LAUNCHED:
      return `${PENDO_TRACK_EVENTS_ENUM.PARTNER_SITE_LAUNCHED} (Service Id: ${id})`;
    case PENDO_TRACK_EVENTS_ENUM.CALCULATOR_LAUNCHED:
      return PENDO_TRACK_EVENTS_ENUM.CALCULATOR_LAUNCHED;
    case PENDO_TRACK_EVENTS_ENUM.HANDBOOK_LAUNCHED:
      return `${PENDO_TRACK_EVENTS_ENUM.HANDBOOK_LAUNCHED} (Service Id: ${id})`;
    case PENDO_TRACK_EVENTS_ENUM.PRE_ENROLLMENT:
      return `${PENDO_TRACK_EVENTS_ENUM.PRE_ENROLLMENT} (Service Id: ${id})`;
    case PENDO_TRACK_EVENTS_ENUM.AD_ACTION_CARD_CLICKED:
      return PENDO_TRACK_EVENTS_ENUM.AD_ACTION_CARD_CLICKED;
    default:
      return '';
  }
};

/**
 * @name trackPendoEvent
 * @description Track Pendo event
 * @param {String} event
 * @param {Object} data
 */
const trackPendoEvent = (event, data) => {
  try {
    event = getEventName(event, data);
    data = {
      ...data,
      environment: Utility.getEnv('REACT_APP_PROFILE'),
    };
    if (event) window.pendo.track(event, data);
  } catch (ex) {
    console.log(ex);
  }
};

/**
 * @name inializePendo
 * @description Initialize Pendo
 * @param {Object} info
 * @param {String} apiKey
 */
const initializePendo = ({ info, apiKey }) => {
  const {
    id = 'guest',
    email = '',
    userFullName = 'guest',
    locationId = '',
    jurisdictionName = '',
    jurisdictionShortCode = '',
    environment = '',
  } = info;

  const pendoOptions = {
    visitor: { id, email, userFullName },
    account: { id: locationId, jurisdictionName, jurisdictionShortCode, environment },
  };

  // let pendo = window.pendo;
  let isPendoInitialize;
  try {
    // eslint-disable-next-line no-undef
    isPendoInitialize = pendo; // this pendo object is coming from  window
  } catch (ex) {
    isPendoInitialize = false;
  }

  if (!isPendoInitialize) {
    (function (p, e, n, d, o) {
      let v, w, x, y, z;
      o = p[d] = p[d] || {};
      o._q = o._q || [];
      v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
      for (w = 0, x = v.length; w < x; ++w)
        (function (m) {
          o[m] =
            o[m] ||
            function () {
              o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
            };
        })(v[w]);
      y = e.createElement(n);
      y.async = !0;
      y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
      z = e.getElementsByTagName(n)[0];
      z.parentNode.insertBefore(y, z);
    })(window, document, 'script', 'pendo');
    // eslint-disable-next-line no-undef
    pendo.initialize(pendoOptions);
  } else {
    // eslint-disable-next-line no-undef
    pendo.identify(pendoOptions);
  }
};

export { PENDO_TRACK_EVENTS_ENUM, trackPendoEvent, initializePendo };
