export default (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.paper,
    padding: '16px 24px',
    borderRadius: '8px',
    gap: 12,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 0 0',
    gap: 24,
  },
  description: {
    color: theme.palette.greyScale[110],
  },
  actionButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
});
