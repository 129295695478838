import { Grid, withStyles } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';

import { PlayAreaTop } from '../';
import { Utility } from '../../../../shared/services';
import { PlayMainService } from '../../services';
import styles from './videos.style';
import { VideoModalPlayer } from '../../../../v2/content/entertainment/components';
import { ENTERTAINMENT_CENTER_CONSTANTS } from '../../../../v2/content/entertainment/services/entertainment-center.service';
import withUnleash from '../../../../core/components/unleash/withUnleash';
import { UnleashService } from '../../../../core/services/unleash/unleash';

const { POST_MESSAGE_EVENT_TYPES } = ENTERTAINMENT_CENTER_CONSTANTS;

const getUrlFromAttributes = (attributes) => {
  const attribute = attributes.filter((attribute) => attribute.attributeName === 'Video Url');
  if (attribute.length) {
    return attribute[0].attributeValue || '';
  }
  return '';
};

const videos = (props) => {
  const playMainService = new PlayMainService();
  const utilityService = new Utility();
  const { classes, attributes, unleashProps } = props;
  const isEntertainmentCenterAdsPocEnabled = unleashProps.isFlagEnabled();

  return (
    <Grid container item>
      {isEntertainmentCenterAdsPocEnabled && <VideoModalPlayer />}
      <PlayAreaTop
        redeem={props.redeem}
        status={props.status.videos}
        schedule={props.schedule}
        tabName="Videos"
        updateRedeemState={props.updateRedeemState}
        updatePlayStatus={props.updatePlayStatus}
      />
      {(props.status.videos.type === playMainService.ON ||
        props.status.videos.type === playMainService.IN_SCHEDULE ||
        props.status.videos.type === playMainService.NO_SCHEDULE_DEFINED) && (
        <iframe
          title="video-resource-frame"
          id="video-resource-frame"
          className={[
            classes.framedElement,
            !props.redeem &&
            props.status.videos.price !== 0 &&
            utilityService.checkStatusExists(props.features, 'Incentive_Engine')
              ? classes.disabledFrame
              : null,
          ].join(' ')}
          src={getUrlFromAttributes(attributes)}
          onLoad={() => {
            const iframe = document.querySelector('#video-resource-frame');
            iframe.contentWindow.postMessage(btoa(JSON.stringify(window.location)), '*');
            iframe.contentWindow.postMessage(
              { type: POST_MESSAGE_EVENT_TYPES.USE_PARENT_WINDOW_PLAYER, value: isEntertainmentCenterAdsPocEnabled },
              '*'
            );
          }}
          allowFullScreen
          width="100%"
          height="1000px"
        ></iframe>
      )}
    </Grid>
  );
};

const mapStateToProps = ({ app }) => app;

export default connect(mapStateToProps)(
  withUnleash(withStyles(styles)(videos), UnleashService.FLAGS.ENTERTAINMENT_CENTER_ADS_POC)
);
