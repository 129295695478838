import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { IntlProvider } from 'react-intl';
import locales from '../../../locale';
import { HashRouter as Router } from 'react-router-dom';

import theme from '../../../v2/core/services/theme/theme';

const Testable = ({ children, initialThemeWidth }) => {
  if (initialThemeWidth) {
    theme.props = { MuiWithWidth: { initialWidth: initialThemeWidth } };
  }
  return (
    <Router>
      <IntlProvider locale="en" messages={locales['en']}>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </IntlProvider>
    </Router>
  );
};

export default Testable;
