import { useEffect, useImperativeHandle, useState } from 'react';

const useExpandCollapseSections = (defaultExpanded = 'top', ref) => {
  const [expandedSection, setExpandedSection] = useState(defaultExpanded);

  useEffect(() => {
    setExpandedSection(defaultExpanded);
  }, [defaultExpanded]);

  const isTopExpanded = expandedSection === 'top';

  const expandTopSection = () => {
    setExpandedSection('top');
  };

  const expandBottomSection = () => {
    setExpandedSection('bottom');
  };

  useImperativeHandle(ref, () => ({
    expandTopSection,
    expandBottomSection,
  }));

  return {
    isTopExpanded,
  };
};

export default useExpandCollapseSections;
