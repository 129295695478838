import * as store from './';

const initialState = {
  currentStep: null,
  summary: null,
  isSummaryLoading: false,
  selectedProgram: null,
};

export const careerCompassReducer = (state = initialState, action) => {
  switch (action.type) {
    case store.SET_CAREER_COMPASS_CURRENT_STEP:
      return {
        ...state,
        currentStep: action.currentStep,
      };
    case store.SET_CAREER_COMPASS_SUMMARY:
      return {
        ...state,
        summary: action.summary,
      };
    case store.SET_CAREER_COMPASS_SUMMARY_LOADING:
      return {
        ...state,
        isSummaryLoading: action.isSummaryLoading,
      };
    case store.SET_SELECTED_PROGRAM:
      return {
        ...state,
        selectedProgram: action.program,
      };
    default:
      return state;
  }
};
