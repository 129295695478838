import React, { Suspense } from 'react';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import { Switch, withRouter, Redirect, Route } from 'react-router-dom';
import { Box, withStyles } from '@material-ui/core';
import ReactGA from 'react-ga';
import { FlagProvider } from '@unleash/proxy-client-react';
import { AuthContainer, CustomChart, Http, Routes, UnleashService, initializePendo, toggleToast } from './core';
import locales from './locale';
import { Constant, EarnTokens, ToastNotification, Utility } from './shared';
import styles from './App.style';
import { Tracker } from './core/services/tracker/tracker';
import { BreadcrumbsProvider } from './v2/core/components';
import { AuthProvider } from './v2/core/auth/providers';
import { checkIfAccessDenied } from './v2/core/auth/utils';
import { Loader } from '@orijinworks/frontend-commons';
import { MessagingService } from './content';

const routes = new Routes();
new Http();
new CustomChart().setRoundedCornerForBars();

const handleSnackBarClose = (props) => {
  props.toggleToast({
    ...props.toast,
    isOpen: false,
  });
};

function App(props) {
  Utility.manageLogout('set');
  const _tracker = new Tracker();
  const isAccessDenied = checkIfAccessDenied(props.location) || false;
  const { userDetail } = props;
  const { PENDO_CONFIG } = Constant;
  const [locale, setLocale] = React.useState('en');
  const [isListenerAppended, setListener] = React.useState(false);
  React.useEffect(() => {
    setLocale(props.locale === 'french' ? 'fr' : props.locale);
  }, [props.locale]);

  React.useEffect(() => {
    if (PENDO_CONFIG.isEnable && PENDO_CONFIG.ENVIRONMENT === Constant.PROFILE) {
      if (PENDO_CONFIG.API_KEY && userDetail && userDetail.userId) {
        const info = {
          id: userDetail.userId,
          userFullName: `${userDetail.firstName} ${userDetail.lastName}`,
          email: userDetail.userName,
          userType: 'authenticated_learner',
          locationId: userDetail.locationId,
          environment: PENDO_CONFIG.ENVIRONMENT,
          jurisdictionName: userDetail.jurisdiction?.name,
          jurisdictionShortCode: userDetail.jurisdiction?.shortCode,
        };
        initializePendo({ info, apiKey: PENDO_CONFIG.API_KEY });
      }
    }
  }, [userDetail, userDetail.id, userDetail.locationId]);

  const translated = locales[`${locale}`];

  if (!isListenerAppended) {
    setListener(true);
    props.history.listen((location, action) => {
      ReactGA.pageview(window.location.pathname + window.location.search);
      _tracker.append(window.location.pathname + window.location.search);
    });
  }
  return (
    <FlagProvider config={UnleashService.CONFIG}>
      <Box component="span" id={MessagingService.ANNOUNCEMENT_ELEMENT} />
      <IntlProvider locale={locale} messages={translated}>
        <ToastNotification
          {...props.toast}
          onClose={() => {
            handleSnackBarClose(props);
          }}
        />

        <AuthProvider>
          <BreadcrumbsProvider>
            <div className="App">
              <AuthContainer isAccessDenied={isAccessDenied}>
                <Suspense fallback={<Loader isLoading={true} />}>
                  <Switch>
                    {routes.getRoutes('private', props.features).map((route) => (
                      <Route key={route.path} {...route} />
                    ))}
                    <Redirect path="**" to="/404" />
                  </Switch>
                </Suspense>
              </AuthContainer>
              {/* Todo: make Public Routes */}
              <Suspense fallback={<Loader isLoading={true} />}>
                <Switch>{renderRoutes(routes.getRoutes('public'))}</Switch>
              </Suspense>
              <EarnTokens />
            </div>
          </BreadcrumbsProvider>
        </AuthProvider>
      </IntlProvider>
    </FlagProvider>
  );
}

const mapStateToProp = (state) => {
  return {
    ...state.app,
    ...state.locale,
    play: state.play,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleToast: (toastData) => dispatch(toggleToast(toastData)),
  };
};

export default withRouter(connect(mapStateToProp, mapDispatchToProps)(withStyles(styles)(App)));
