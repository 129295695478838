export default (theme) => ({
  approvedContactHeader: {
    fontFamily: 'Inter',
    fontSize: '24px',
    fontWeight: '700',
    lineHeight: '29.05px',
    color: '#0F2C54',
    padding: '8px 24px',
  },
  contactContainer: {
    display: 'flex',
    width: '611px',
    maxWidth: '100%',
    borderRadius: '10px',
    backgroundColor: theme.palette.primary.white,
    border: `0.5px solid ${theme.palette.primary.greyed}`,
    marginLeft: '24px',
    marginTop: '14px',
    padding: '16px',
    alignItems: 'flex-start',
    gap: '10px',
  },
  contactInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
  },
  contactName: {
    fontFamily: 'Inter',
    fontWeight: '700',
    fontSize: '20px',
    lineHeight: '24.2px',
    color: '#0F2C54',
  },
  contactRelationship: {
    fontFamily: 'Rubik',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '21.33px',
    color: '#0078A2',
  },
  contactNumber: {
    fontFamily: 'Inter',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#333333',
  },
  dialIconButton: {
    alignSelf: 'flex-start',
    marginLeft: 'auto',
    marginRight: '-10px',
    color: theme.palette.primary.main,
  },
});
