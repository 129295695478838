export default (theme) => ({
  container: {
    margin: '24px 24px 24px 101px',
  },
  boxHeightExpanded: {
    height: 'calc(100dvh - 275px)',
  },
  boxHeightCollapsed: {
    height: 'calc(100dvh - 136px)',
  },
  pillButton: {
    backgroundColor: `${theme.palette.navy.main} !important`,
  },
});
