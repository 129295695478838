export default (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  rootExpanded: {
    padding: '12px 0px',
    gap: '12px',
  },
  icon: {
    width: 24,
    height: 24,
    color: theme.palette.aqua.extraDeep,
  },
  progressInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: '3px',
    flex: '1 0 0',
  },
  progressHeading: {
    color: theme.palette.greyScale[110],
    textTransform: 'uppercase',
    letterSpacing: '1.4px',
    lineHeight: 'normal',
  },
  programsCompleted: {
    color: theme.palette.aqua.extraDeep,
    fontWeight: '700 !important',
    textTransform: 'capitalize',
  },
});
