export default (theme) => ({
  heading: {
    marginTop: '16px',
    color: '#0F2C54',
    padding: '8px 24px',
    fontSize: '24px',
    fontFamily: 'Inter',
    fontWeight: '700',
    lineHeight: '29.05px',
  },
  subtext: {
    marginBottom: '32px',
    fontSize: '16px',
    fontFamily: 'Inter',
    fontWeight: '400',
    lineHeight: '24px',
    color: theme.palette.greyScale['110'],
    marginLeft: '24px',
  },
  hotlineContacts: {
    alignItems: 'flex-start',
    display: 'flex',
    gap: '10px',
  },
  sosIcon: {
    background: theme.palette.green.pale,
    borderRadius: '50%',
    color: theme.palette.primary.deep,
    width: '50px !important',
    height: '50px !important',
    padding: 10,
    alignSelf: 'flex-start',
  },
  hotlineContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: '100%',
  },
  contactTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
  },
  contactName: {
    fontFamily: 'Inter',
    fontWeight: '700',
    fontSize: '20px',
    lineHeight: '24px',
    color: '#0F2C54',
  },
  contactNumber: {
    fontFamily: 'Inter',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    color: theme.palette.greyScale['110'],
  },
  contactRow: {
    paddingTop: '4px',
    marginLeft: '24px',
    width: '100%',
    maxWidth: '611px',
    marginBottom: 10,
    borderRadius: 10,
    boxShadow: 'none',
    border: `0.5px solid ${theme.palette.primary.greyed}`,
    marginRight: '12px',
  },
  dialButton: {
    marginRight: '-10px',
    borderRadius: '20px',
    color: theme.palette.primary.main,
    alignSelf: 'flex-start',
  },
});
