import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, IconButton, withStyles } from '@material-ui/core';
import styles from './career-compass-progress.styles';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const CareerCompassProgress = ({ classes, ...props }) => {
  const { title, description, progressElement, isExpanded, onOpen } = props;

  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        <Typography variant={isExpanded ? 'h2' : 'h3'}>{title}</Typography>
        {description && (
          <Typography variant="body1" className={classes.description}>
            {description}
          </Typography>
        )}
        {progressElement && <>{progressElement}</>}
      </Box>
      {!isExpanded && (
        <IconButton className={classes.actionButton} onClick={onOpen} aria-label="Open Progress Page">
          <KeyboardArrowUpIcon />
        </IconButton>
      )}
    </Box>
  );
};

CareerCompassProgress.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  progressElement: PropTypes.element,
  isExpanded: PropTypes.bool.isRequired,
  onOpen: PropTypes.func,
};

export default withStyles(styles)(CareerCompassProgress);
