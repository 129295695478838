export default (theme) => ({
  unitsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  unitItem: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '12px 0',
    marginLeft: '64px',
  },
  unitHeader: {
    display: 'block',
    alignItems: 'center',
  },
  unitTitle: {
    color: theme.palette.greyScale[110],
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '22px',
    letterSpacing: '0.28px',
  },
  unitName: {
    display: 'flex',
    alignItems: 'center',
  },
  notStarted: {
    marginLeft: '28px',
  },
  inProgress: {
    display: 'block',
    alignItems: 'center',
    '& $unitTitle': {
      fontWeight: 700,
    },
  },
  unitProgress: {
    color: theme.palette.greyScale[110],
    marginLeft: '28px',
  },
  unitDivider: {
    margin: theme.spacing(0, 1),
  },
  completedIcon: {
    color: theme.palette.success.main,
    fontSize: 20,
    marginRight: '12px',
    width: '16px !important',
    height: '16px !important',
  },
  inProgressIcon: {
    color: theme.palette.blue.primary,
    fontSize: 20,
    marginRight: '12px',
    width: '16px !important',
    height: '16px !important',
  },
  unitLaunchButton: {
    color: theme.palette.blue.primary,
    padding: 8,
    height: '24px',
    width: '24px',
  },
});
