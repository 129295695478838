import { useCallback, useEffect, useState } from 'react';

const useModuleUnit = (unit) => {
  const [counts, setCounts] = useState({
    completed: 0,
    inProgress: 0,
    total: 0,
  });

  const hasProgress = useCallback(() => {
    const hasCompleted = Boolean(unit?.progress?.completed);
    const hasTotal = Boolean(unit?.progress?.total);
    return hasCompleted && hasTotal;
  }, [unit]);

  const getCounts = useCallback(() => {
    if (hasProgress()) {
      const completed = unit?.progress?.completed ?? 0;
      const total = unit?.progress?.total ?? 0;
      const inProgress = total - completed;
      setCounts({ completed, inProgress, total });
    } else {
      setCounts({ completed: 0, inProgress: 0, total: 0 });
    }
  }, [unit, hasProgress]);

  useEffect(() => {
    getCounts();
  }, [getCounts]);

  const isCompleted = counts.completed === counts.total && counts.total > 0;
  const isInProgress = counts.completed > 0 && counts.completed < counts.total;
  const isNotStarted = counts.completed === 0;

  return {
    isCompleted,
    isInProgress,
    isNotStarted,
  };
};

export default useModuleUnit;
