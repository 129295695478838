export default (theme) => ({
  modal: {
    position: 'absolute',
    top: '50%',
    left: '52%',
    transform: 'translate(-50%, -50%)',
    width: 430,
    backgroundColor: 'white',
    borderColor: theme.palette.primary.grayLight,
    padding: theme.spacing(3),
    borderRadius: '24px',
  },
  heading: {
    marginBottom: theme.spacing(1),
    paddingTop: '10px',
    color: `${theme.palette.primary.darkBlue} !important`,
    fontSize: '20px',
  },

  headingRestricted: {
    marginBottom: theme.spacing(1),
    paddingTop: '24px',
    color: `${theme.palette.primary.darkBlue} !important`,
    fontSize: '20px',
  },
  dismissBtn: {
    cursor: 'pointer',
    fontWeight: 'bold',
    marginTop: '20px',
    color: theme.palette.primary.white,
    borderRadius: '30px',
    height: '44px',
    width: '114px',
    backgroundColor: `${theme.palette.voice.dismissBtn.backgroundColor}`,
    '&:hover': {
      backgroundColor: `${theme.palette.voice.dismissBtn.backgroundColor} !important`,
    },
  },

  paragraph: {
    whiteSpace: 'pre-line',
    paddingTop: '20px',
  },

  callRestricted: {
    padding: '0px !important',
  },
  paragraphRestricted: {
    whiteSpace: 'pre-line',
  },
  requestRejected: {
    color: theme.palette.primary.alertRed,
    fontWeight: 'bold',
  },
  requestBlocked: {
    color: theme.palette.primary.grayDark,
    fontWeight: 'bold',
  },
  requestSent: {
    color: theme.palette.primary.green,
    fontWeight: 'bold',
  },
  requestFailed: {
    fontWeight: 'bold',
  },
  requestRestricted: {
    color: theme.palette.primary.alertRed,
    fontWeight: 'bold',
  },
  important: {
    color: theme.palette.primary.mediumBlue,
    fontWeight: 'bold',
  },
});
