import React from 'react';
import { Box, Chip, useTheme, withStyles } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './program-details.container.styles';
import { ExpandCollapseSections } from '../../../../shared';
import PropTypes from 'prop-types';
import ProgramDetails from '../../components/program-details/program-details';
import NavigationCard from '../../components/navigation-card/navigation-card';
import CareerCompassProgress from '../../components/career-compass-progress/career-compass-progress';
import TotalProgress from '../../components/total-progress/total-progress';
import { PillButton } from '../../../../../frontend-commons';
import withUnleash from '../../../../../core/components/unleash/withUnleash';
import { UnleashService } from '../../../../../core/services/unleash/unleash';
import { CAREER_COMPASS_CONSTANTS } from '../../services/career-compass.service';
import withCareerCompassAuth from '../career-compass/withCareerCompassAuth';
import useProgramDetailsContainerHook from './useProgramDetailsContainerHook';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CareerCompassSummary from '../../components/summary/career-compass-summary';
import { theme } from '../../../../core';

const ProgramDetailsContainer = ({
  programId,
  summary,
  currentStep,
  selectedLocale,
  handleCurrentStepUpdate,
  unleashProps,
  selectedProgram,
  classes,
}) => {
  const {
    expandCollapseSectionsRef,
    expandTopSection,
    expandBottomSection,
    showExpandCollapseSections,
    hasMorePrograms,
    isCertificateAwarded,
    onPickNextProgram,
  } = useProgramDetailsContainerHook({ unleashProps, currentStep, selectedProgram, handleCurrentStepUpdate });
  const intl = useIntl();

  const renderNavigationCardCollapsed = () => {
    // Check if no more programs and current program is completed
    if (!hasMorePrograms() && isCertificateAwarded()) {
      return (
        <NavigationCard
          title={<FormattedMessage id="career-compass.progress-more-progress-coming-soon" />}
          backgroundColor={theme.palette.aqua.extraDeep}
        />
      );
    }

    // Check if a current program is completed and there are more programs
    else if (isCertificateAwarded() && hasMorePrograms()) {
      return (
        <NavigationCard
          title={<FormattedMessage id="career-compass.progress-program-selection-header" />}
          subtitle={<FormattedMessage id="career-compass.progress-program-selection-subtitle" />}
          backgroundColor={theme.palette.aqua.extraDeep}
          actionButton={
            <PillButton
              size="large"
              variant="contained"
              className={classes.pickProgramButton}
              aria-label="pick-program"
              data-testid="pick-program"
              onClick={onPickNextProgram}
            >
              <FormattedMessage id="pickProgram" />
            </PillButton>
          }
        />
      );
    }

    // Default case when program is in progress
    else {
      return (
        <NavigationCard
          title={selectedProgram?.name ?? ''}
          subtitle={
            <Chip
              label={intl.formatMessage({ id: 'career-compass.progress-program-details-subtitle' })}
              className={classes.chip}
            />
          }
          backgroundImage={selectedProgram?.thumbnailUri ?? ''}
          actionButton={
            <PillButton
              size="large"
              variant="contained"
              className={classes.pillButton}
              aria-label="keep-going"
              data-testid="keep-going"
              onClick={expandTopSection}
              endIcon={<KeyboardArrowDownIcon size={16} fill="white" />}
            >
              <FormattedMessage id="keepGoing" />
            </PillButton>
          }
        />
      );
    }
  };

  return (
    <Box className={classes.container}>
      {showExpandCollapseSections() ? (
        <ExpandCollapseSections
          ref={expandCollapseSectionsRef}
          topSection={{
            expanded: (
              <Box className={classes.boxHeightExpanded}>
                <ProgramDetails
                  currentStep={currentStep}
                  selectedLocale={selectedLocale}
                  handleCurrentStepUpdate={handleCurrentStepUpdate}
                  programId={programId}
                />
              </Box>
            ),
            collapsed: renderNavigationCardCollapsed(),
          }}
          bottomSection={{
            expanded: <CareerCompassSummary />,
            collapsed: (
              <CareerCompassProgress
                title={<FormattedMessage id="career-compass.progress.title" />}
                isExpanded={false}
                onOpen={hasMorePrograms() && isCertificateAwarded() ? onPickNextProgram : expandBottomSection}
                progressElement={
                  <TotalProgress
                    progressHeading={<FormattedMessage id="career-compass.progress.heading" />}
                    programsCompleted={
                      <FormattedMessage
                        id="career-compass.programs-completed"
                        values={{ count: summary?.programsCompleted || 0 }}
                      />
                    }
                  />
                }
              />
            ),
          }}
          defaultExpanded="top"
        />
      ) : (
        <Box className={classes.boxHeightCollapsed}>
          <ProgramDetails
            currentStep={currentStep}
            selectedLocale={selectedLocale}
            handleCurrentStepUpdate={handleCurrentStepUpdate}
            programId={programId}
          />
        </Box>
      )}
    </Box>
  );
};

ProgramDetailsContainer.propTypes = {
  currentStep: PropTypes.object,
  selectedLocale: PropTypes.string.isRequired,
  handleCurrentStepUpdate: PropTypes.func.isRequired,
  summary: PropTypes.shape({
    programsCompleted: PropTypes.number,
    summaryItems: PropTypes.arrayOf(
      PropTypes.shape({
        category: PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
        }),
        programs: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            title: PropTypes.string,
            isCompleted: PropTypes.bool,
            isStarted: PropTypes.bool,
          })
        ),
      })
    ),
  }),
  classes: PropTypes.object.isRequired,
  programId: PropTypes.string.isRequired,
  selectedProgram: PropTypes.object,
};

const WrappedComponent = withUnleash(
  withStyles(styles)(ProgramDetailsContainer),
  UnleashService.FLAGS.ENABLE_CAREER_COMPASS_PROGRESS
);
export default withCareerCompassAuth(WrappedComponent, CAREER_COMPASS_CONSTANTS.PAGES.PROGRAM_DETAILS);
