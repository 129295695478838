import React from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress, withStyles } from '@material-ui/core';
import styles from './career-compass-summary.styles';
import { connect } from 'react-redux';
import CareerCompassSummarySection from '../summary-section/career-compass-summary-section';
import CareerCompassProgress from '../career-compass-progress/career-compass-progress';
import TotalProgress from '../total-progress/total-progress';
import { FormattedMessage } from 'react-intl';

/**
 * Component that renders the Career Compass Summary
 *
 * @param {object} props - The component props
 * @returns {JSX.Element}
 */
const CareerCompassSummary = ({ classes, summary }) => {
  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <CareerCompassProgress
          title={<FormattedMessage id="career-compass.progress.title" />}
          description={<FormattedMessage id="career-compass.progress.description" />}
          isExpanded
          progressElement={
            summary ? (
              <TotalProgress
                progressHeading={<FormattedMessage id="career-compass.progress.heading" />}
                programsCompleted={
                  <FormattedMessage
                    id="career-compass.programs-completed"
                    values={{ count: summary.programsCompleted || 0 }}
                  />
                }
                isExpanded
              />
            ) : (
              <Box padding="24px">
                <CircularProgress color="secondary" />
              </Box>
            )
          }
        />
      </Box>

      {summary?.summaryItems?.length > 0 ? (
        <Box className={classes.sectionsContainer}>
          {summary.summaryItems.map(
            (item) =>
              item.programs.length > 0 && (
                <CareerCompassSummarySection key={item.category.id} category={item.category.name} programs={item.programs} />
              )
          )}
        </Box>
      ) : (
        <Box padding="24px">
          <CircularProgress color="secondary" />
        </Box>
      )}
    </Box>
  );
};

CareerCompassSummary.propTypes = {
  /**
   * The classes object to use for styling that will be injected by withStyles.
   */
  classes: PropTypes.object.isRequired,
  /**
   * The summary object.
   */
  summary: PropTypes.shape({
    programsCompleted: PropTypes.number,
    summaryItems: PropTypes.arrayOf(
      PropTypes.shape({
        category: PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
        }),
        programs: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            title: PropTypes.string,
            isCompleted: PropTypes.bool,
            isStarted: PropTypes.bool,
          })
        ),
      })
    ),
  }),
};

const mapStateToProps = ({ careerCompass }) => {
  return {
    summary: careerCompass.summary,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(CareerCompassSummary));
