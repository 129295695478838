export default (theme) => ({
  root: {
    '&$progCard': {
      display: 'flex',
      cursor: 'pointer',
      boxShadow: '0px 4px 8px 2px rgba(142, 142, 142, 0.1)',
      backgroundColor: theme.palette.primary.white,
      position: 'relative',
      borderRadius: 5,
      minHeight: 0,
      textDecoration: 'none',
      width: '100%',
      padding: 0,
    },
    '&$progCard:hover': {
      backgroundColor: theme.palette.primary.white,
    },
  },
  progCard: {
    // need this for the class to show up
  },
  thumbnailContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    minHeight: 'inherit',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '190px',
    padding: '15px 0px',
  },
  cardTitle: {
    fontFamily: theme.typography.secondaryFont,
    ...theme.typography.t24,
    '-webkit-line-clamp': 2,
    textOverflow: 'ellipsis',
    fontWeight: 700,
    color: theme.palette.primary.dark,
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    margin: '20px 20px 10px',
    padding: 0,
    fontFamily: theme.typography.secondaryFont,
  },
  providerLogoContainer: {
    zIndex: 99,
    position: 'absolute',
    bottom: 0,
    left: '15px',
    borderRadius: '5px 5px 0 0',
    background: theme.palette.primary.white,
    padding: 10,
    maxWidth: '85%',
  },
  providerLogo: {
    maxHeight: '100%',
    width: 'auto',
    height: '30px',
  },
  thumbnail: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    borderRadius: '5px 0 0 5px',
  },
  actionBtn: {
    borderRadius: 100,
    padding: '12px 20px',
    '&$viewProgBtn': {
      ...theme.typography.t18,
      ...theme.typography.bold,
      lineHeight: '16px',
      letterSpacing: '1px',
      textTransform: 'capitalize',
      textDecoration: 'none',
      color: theme.palette.primary.main,
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.pale,
    },
  },
  viewProgBtn: {
    // need this for the class to show up
  },
  actionBox: {
    marginLeft: '20px',
    marginBottom: 5,
    width: 'fit-content',
  },
  description: {
    fontSize: '1rem',
    '-webkit-line-clamp': 4,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '-webkit-box-orient': 'vertical',
    display: '-webkit-box',
    workBreak: 'break-all',
  },
  banner: {
    top: '0',
    color: 'white',
    right: '12px',
    display: 'flex',
    padding: '12px',
    position: 'absolute',
    background: theme.palette.primary.main,
    boxShadow: '4px 4px 15px rgba(26, 35, 126, 0.2)',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '0px 0px 4px 4px',
    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
    paddingRight: '18px',
  },
  bannerText: {
    marginLeft: '10px',
    fontWeight: 700,
  },
  logoText: {
    color: theme.palette.greyScale.grey01,
    fontSize: '16px',
    fontWeight: '400',
  },
  categoryChip: {
    color: theme.palette.primary.white,
    borderRadius: '4px',
    padding: '6px 12px',
    margin: '5px 0',
    width: 'fit-content',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'normal',
    letterSpacing: '0.32px',
  },
  tagText: {
    textTransform: 'capitalize',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '22px',
    letterSpacing: '0.28px',
    color: theme.palette.greyScale[100],
    padding: '0px 0px 0px 20px',
  },
  logoContainer: {
    marginBottom: 12,
  },
  bgRehabilitation: {
    backgroundColor: theme.palette.aqua.extraDeep,
  },
  bgWorkforce: {
    backgroundColor: theme.palette.colors.purpleDeep,
  },
  bgEducation: {
    backgroundColor: theme.palette.orange.dark,
  },
  bgPersonalGrowth: {
    backgroundColor: theme.palette.cerulean.cerulean20,
  },
  bgResource: {
    backgroundColor: theme.palette.yellow.dark,
  },
});
