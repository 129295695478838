import React from 'react';

const CallRestrictedIcon = (props) => {
  const { size, fill } = props;
  return (
    <div
      style={{
        width: '48px',
        height: '48px',
        borderRadius: '50%',
        backgroundColor: '#E32D4E',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <svg
        width="32px"
        role="img"
        aria-label="call-disabled"
        height="32px"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.95002 13.8301L7.38002 12.4001C6.82002 11.6701 6.33003 10.9001 5.91003 10.0801L8.11002 7.8801C8.39002 7.6001 8.47002 7.2101 8.36002 6.8601C7.99002 5.7401 7.79002 4.5401 7.79002 3.2901C7.79002 2.7401 7.34002 2.2901 6.79002 2.2901H3.29002C2.74002 2.2901 2.29002 2.7401 2.29002 3.2901C2.29002 7.2701 3.66002 10.9301 5.95002 13.8301ZM8.77002 16.6401C11.66 18.9301 15.32 20.2901 19.29 20.2901C19.84 20.2901 20.29 19.8401 20.29 19.2901V15.8001C20.29 15.2501 19.84 14.8001 19.29 14.8001C18.05 14.8001 16.84 14.6001 15.72 14.2301C15.37 14.1101 14.97 14.2001 14.7 14.4701L12.5 16.6701C11.69 16.2501 10.92 15.7701 10.2 15.2101L21.9 3.5101L20.48 2.1001L2.10002 20.4901L3.51002 21.9001L8.77002 16.6401Z"
          fill="#fff"
        />
      </svg>
    </div>
  );
};

CallRestrictedIcon.defaultProps = {
  size: 24,
};

export default CallRestrictedIcon;
