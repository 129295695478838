import { Http, store, toggleToast } from '../../../core';
import { Constant } from '../constant/constant';
import moment from 'moment-timezone';
import { UserService } from '../user/user';
import DOMPurify from 'dompurify';

export class Utility {
  static USER_DATA = null;
  colors = [
    '#F44336',
    '#e91e63',
    '#9c27b0',
    '#673ab7',
    '#ff9800',
    '#ff5722',
    '#795548',
    '#607d8b',
    '#3f51b5',
    '#2196F3',
    '#00bcd4',
    '#009688',
    '#2196F3',
    '#32c787',
    '#00BCD4',
    '#ff5652',
    '#ffc107',
    '#ff85af',
    '#FF9800',
    '#39bbb0',
    '#4CAF50',
    '#ffeb3b',
    '#ffc107',
  ];
  static exploreChildWindows = [];
  MENU_ENUM = {
    Pathways: 'Pathways',
    Achievements: 'Achievements',
    Explore: 'Explore',
    Play: 'Play',
    404: '404',
  };
  debounceRef = null;
  debounceTime = 500;
  _relativePathPrefix = '__RELATIVE_PATH::';

  /**
   * @name icon
   * @property GET
   * @desc Endpoint to get icon from centralized position.
   * @return {void}
   */
  static get icon() {
    return `${process.env.PUBLIC_URL}/assets/icons/`;
  }

  static manageLogout = (type) => {
    switch (type) {
      case 'set':
        sessionStorage.setItem('isLogoutInitiated', true);
        break;
      case 'delete':
        sessionStorage.removeItem('isLogoutInitiated');
        break;
      case 'get':
        return sessionStorage.getItem('isLogoutInitiated');
      default:
        return null;
    }
  };

  /**
   * @name getTimezoneName
   * @param {string} timestamp
   * @desc From timestamp, timezone name is retrieved and returned.
   * @return {string}
   */
  getTimezoneName = (timestamp) => {
    const knownTimezones = ['US/Eastern', 'US/Pacific', 'US/Central', 'US/Alaska', 'US/Hawaii', 'US/Mountain'];
    const userUTCOffset = moment.parseZone(timestamp).format('Z');
    const timezoneName = moment.tz
      .names()
      .filter((tz) => moment.tz(tz).format('Z') === userUTCOffset)
      .find((tzName) => knownTimezones.indexOf(tzName) > -1);
    return {
      utcOffset: userUTCOffset,
      timezoneName: timezoneName,
    };
  };

  /**
   * @name parseTimeToNewZone
   * @param {string} actualTimestamp
   * @param {string} newTimezone
   * @param {string} format
   * @desc Parses actual timestamp to new timezone, if format is provided then time is formatted into it.
   * Otherwise, it formats into default timezone.
   * @return {string}
   */
  parseTimeToNewZone = (actualTimestamp, newTimezone, format) => {
    if (format) {
      return `${moment.utc(actualTimestamp).tz(newTimezone).format(format)}`;
    } else {
      return `${moment.utc(actualTimestamp).tz(newTimezone).format('MMM D YYYY hh:mm A')} (${newTimezone})`;
    }
  };

  /**
   * @name checkStatusExists
   * @param {array} statusCollection Collection of statuses.
   * @param {string} featureName Name of feature that needs to be checked.
   * @desc Checks if status exists in collection or not.
   * @return {boolean}
   */
  checkStatusExists = (statusCollection = [], featureName) => {
    const feature = statusCollection.find((status) => status.featureName === featureName);
    if (feature) {
      return feature.status;
    }
    return false;
  };

  /**
   * @name toggleToast
   * @param {object} data Toast object data.
   * @desc Toggles toast with data passed.
   * @return {void}
   */
  toggleToast = (data) => {
    store.dispatch(toggleToast(data));
  };

  /**
   * @name launchURLHandler
   * @param {string} serviceName
   * @param {Promise} getURLCallback Callback method that returns URL.
   * @desc Handles launch w.r.t service.
   * @return {void}
   */
  launchURLHandler = async (serviceName, getURLCallback) => {
    switch (serviceName) {
      case Constant.EXPLORE_SERVICES.Kolibri && window.KOLIBRI_AUTH_TYPE === 'MANUAL':
        this.authenticateKolibri(this.toggleToast, () => this.launchURLHandler(null, getURLCallback));
        break;
      default:
        try {
          const url = await this.getServiceURL(getURLCallback);
          window.open(url, '_blank');
        } catch (e) {
          return;
        }
    }
  };

  /**
   * @name getServiceURL
   * @param {number} serviceId
   * @desc Fetch's service URL.
   * @return {Promise}
   */
  getServiceURL = (getURLCallback) => {
    return new Promise(async (resolve, reject) => {
      try {
        const _successLog = (await getURLCallback()).data;
        if (!_successLog) {
          store.dispatch(
            toggleToast({
              isOpen: true,
              variant: 'error',
              message: 'error.msg.launch.service',
              showCancelButton: true,
            })
          );
          reject(false);
          return;
        }
        resolve(_successLog);
      } catch (e) {
        reject(e);
        console.log(e);
      }
    });
  };

  /**
   * @name authenticateKolibri
   * @param {function} toggleToast Method to show toast message.
   * @param {function} fallback Fall back method in case SSO fails.
   * @desc Authenticates kolibri via SSO process.
   * @return {void}
   */
  authenticateKolibri = async (toggleToast, fallback) => {
    try {
      const kolibriRedirectUrl = process.env.REACT_APP_KOLIBRI_REDIRECT_URL;
      if (!kolibriRedirectUrl) {
        // IF THERE'S NO REDIRECTION
        // SPECIFIED THEN CANCELLING NEW
        // PROCESS
        throw new Error('Redirect environment variable not found.');
      }
      const _successLog = (await Http.REQUEST.get('explore/sso/kolibri')).data;
      const hasAllProperty =
        _successLog.hasOwnProperty('visitor_id') &&
        _successLog.hasOwnProperty('kolibri_csrftoken') &&
        _successLog.hasOwnProperty('kolibri');
      if (hasAllProperty) {
        this.openChildWindow(
          `${kolibriRedirectUrl}?visitor_id=${_successLog.visitor_id}&kolibri_csrftoken=${_successLog.kolibri_csrftoken}&kolibri=${_successLog.kolibri}`
        );
      } else {
        throw new Error('Property Missing');
      }
    } catch (e) {
      console.log('Error while authenticating Kolibri:', e);
      fallback();
    }
  };

  /**
   * @name postMessageOnIframeResource
   * @param {string} iframeId
   * @desc Starts timeout and keeps running unless frame is found and then message is posted.
   * @return {void}
   */
  postMessageOnIframeResource = (iframeID) => {
    const iframeInterval = setInterval(() => {
      const iframe = document.querySelector(`#${iframeID}`);
      if (iframe) {
        clearInterval(iframeInterval);
        iframe.contentWindow.postMessage(btoa(JSON.stringify(window.location)), '*');
      }
    }, 500);
  };

  /**
   * @name openChildWindow
   * @param {string} url
   * @desc Opens child window with url provided.
   * @return {void}
   */
  openChildWindow = (url) => {
    const childWindowRef = window.open(url, '_blank', `width=${window.innerWidth}, height=${window.innerHeight}`);
    Utility.exploreChildWindows.push(childWindowRef);
  };

  /**
   * @name closeAllExploreChildWindow
   * @desc Closes all child window in stack.
   * @return {void}
   */
  closeAllExploreChildWindow = () => {
    Utility.exploreChildWindows.forEach((childWindow) => {
      if (childWindow && !childWindow.closed) {
        childWindow.close();
      }
    });
    Utility.exploreChildWindows = [];
  };

  /**
   * @name getTitleFromUrl
   * @param {string} type Based upon type, title is modified and returned.
   * @desc Based upon url title of current page is fetched.
   * Could be user for general purpose such as title / permissions etc..
   * @return {string} title
   */
  getTitleFromUrl = (type) => {
    let key = window.location.pathname.split('/')[1];

    const isProgramDetails = /\/programs\/([\d])+\/details$/.test(window.location.pathname);
    const isCareerProfile = /\/careers\/(.)+\/profile$/.test(window.location.pathname);
    const isIPQuestionnaireResults = /\/interest-profiler\/results$/.test(window.location.pathname);
    const isPDQAssessmentIntro = /\/pdq-assessment\/intro$/.test(window.location.pathname);
    const isPDQAssessmentResults = /\/pdq-assessment\/results$/.test(window.location.pathname);
    const isCareerPathDetails = /\/career-paths\/([\d])+\/details$/.test(window.location.pathname);
    const isCareerCompassIntro = /\/career-compass\/introduction$/.test(window.location.pathname);
    const isCareerCompassProgramSelection = /\/career-compass\/program-selection$/.test(window.location.pathname);
    const isCareerCompassProgramDetails = /\/career-compass\/program-details\/([\d])+/.test(window.location.pathname);

    const isCareerCompassPage = isCareerCompassIntro || isCareerCompassProgramSelection || isCareerCompassProgramDetails;

    if (isProgramDetails) key = 'programDetails';
    if (isCareerProfile) key = 'careerProfile';
    if (isIPQuestionnaireResults) key = 'interest-profiler';
    if (isPDQAssessmentIntro) key = 'pdq-aseessment';
    if (isPDQAssessmentResults) key = 'pdq-aseessment-results';
    if (isCareerPathDetails) key = 'career-paths-details';
    if (isCareerCompassPage) key = 'career-compass';

    let titles = {};
    switch (type) {
      case 'heading':
        titles = {
          pathways: 'pathways',
          'pathway-detail': 'pathway',
          explore: 'explore',
          achievements: 'achievements',
          entertain: 'entertainment',
          404: '404',
          forms: 'forms',
          messaging: 'messaging',
          home: 'home',
          'friends-family': 'FnFMessaging',
          programs: 'programCatalog',
          careers: 'careerDirectory',
          programDetails: 'programDetails',
          bookmarks: 'bookmarks',
          transcript: 'transcript',
          'interest-profiler': 'interestProfiler',
          'my-profile': 'myProfile',
          'pdq-aseessment': 'milestoneMapper',
          'pdq-aseessment-results': 'milestoneMapperResults',
          'career-paths': 'careerPaths',
          'career-paths-details': 'careerPathDetails',
          'voice-call': 'voiceCall',
          'resume-builder': 'resumeBuilder',
          'resume-preview': 'resumePreview',
          'career-compass': 'careerCompass',
        };
        break;

      case 'permission':
        titles = {
          pathways: 'Pathways',
          'pathway-detail': 'Pathways',
          explore: 'Explore',
          achievements: 'Achievements',
          play: 'Play',
          forms: 'Forms',
          messaging: 'Messaging',
          'friends-family': 'FF_Messaging',
          'resume-builder': 'Resume_Builder',
          'resume-preview': 'Resume_Builder',
          'voice-call': 'Voice_Calls',
          'pdq-aseessment': 'Milestone_Mapper',
          'pdq-aseessment-results': 'Milestone_Mapper',
          'career-compass': 'Career_Compass',
        };
        break;

      case 'learner_permission':
        titles = {
          pathways: 'Pathways',
          'pathway-detail': 'Pathways',
          explore: 'Explore',
          achievements: 'Achievements',
          play: 'Play',
          forms: 'Forms',
          messaging: 'Messaging_Person',
          'friends-family': 'FF_Messaging',
          'resume-builder': 'Resume_Builder',
          'resume-preview': 'Resume_Builder',
          'voice-call': 'Voice_Calls',
          'pdq-aseessment': 'Milestone_Mapper',
          'pdq-aseessment-results': 'Milestone_Mapper',
          'career-compass': 'Career_Compass',
        };
        break;

      default:
        break;
    }
    return titles[key] || '';
  };

  /**
   * @name debounceMethod
   * @desc Implements dobounce strategy in order to avoid un-necessary calls.
   * @return {Promise}
   */
  debounceMethod = () => {
    return new Promise((resolve, reject) => {
      clearTimeout(this.debounceRef);
      this.debounceRef = setTimeout(() => {
        resolve(true);
      }, this.debounceTime);
    });
  };

  /**
   * @name getInitiallyActivateContentArea
   * @param {boolean} isEnrichAvailable A check to add enrichment content area available.
   * @desc Content areas which are active at initial load
   * is returned.
   * @return {array} allowedListOfContentAreas
   */
  getInitiallyActivateContentArea = (isEnrichAvailable = false) => {
    const contentAreas = [
      Constant.CONTENT_AREAS_ENUM.Education,
      Constant.CONTENT_AREAS_ENUM.Rehab,
      Constant.CONTENT_AREAS_ENUM.Workforce,
      Constant.CONTENT_AREAS_ENUM.ReEntry,
    ];
    if (isEnrichAvailable) {
      contentAreas.push(Constant.CONTENT_AREAS_ENUM.Enrichment);
    }
    return contentAreas;
  };

  /**
   * @name getFilterableContentAreas
   * @param {array} listOfContentAreas
   * @desc Filters allowed content areas from listOfContentAreas.
   * @return {array} allowedListOfContentAreas
   */
  getFilterableContentAreas = (listOfContentAreas) => {
    let contentValuesAllowed = [
      Constant.CONTENT_AREAS_ENUM.Education,
      Constant.CONTENT_AREAS_ENUM.Rehab,
      Constant.CONTENT_AREAS_ENUM.Workforce,
      Constant.CONTENT_AREAS_ENUM.ReEntry,
      Constant.CONTENT_AREAS_ENUM.Enrichment,
    ];
    return listOfContentAreas.filter((contentAreaID) => contentValuesAllowed.indexOf(contentAreaID) > -1);
  };

  /**
   * @name getContentAreas
   * @param {object} contentAreas Redux content areas.
   * @desc Fetches content areas from backend service.
   * @return {Promise}
   */
  getContentAreas = (cacheContentAreas) => {
    let contentValuesAllowed = [
      Constant.CONTENT_AREAS_ENUM.Education,
      Constant.CONTENT_AREAS_ENUM.Rehab,
      Constant.CONTENT_AREAS_ENUM.Workforce,
      Constant.CONTENT_AREAS_ENUM.ReEntry,
      Constant.CONTENT_AREAS_ENUM.Enrichment,
    ];
    return new Promise(async (resolve, reject) => {
      if (cacheContentAreas.collection.length > 0) {
        resolve(cacheContentAreas.collection);
        return;
      }

      let contentAreas = await Http.REQUEST.get('/resource/contentArea');
      contentAreas = contentAreas.data
        .filter((contentArea) => contentValuesAllowed.indexOf(contentArea.contentId) > -1)
        .map((contentArea) => Constant.CONTENT_AREA[contentArea.contentId]);

      resolve(contentAreas);
    });
  };

  /**
   * @name isTokenAddOrSubtract
   * @param {string} type
   * @desc Based upon type add / subtract decision is made.
   * @return {string}
   */
  isTokenAddOrSubtract = (type) => {
    switch (type) {
      case 'Reward':
      case 'Credit':
        return 'add';

      case 'Redeem':
      case 'Debit':
        return 'subtract';
      default:
        return '';
    }
  };

  /**
   * @name getAvatarColor
   * @param {string} name Name of color.
   * @desc Calculates color for avatar background.
   * @return {string} colorHex
   */
  getAvatarColor(name) {
    name = name.substr(0, 6);
    var hash = 0;
    for (var i = 0; i < name.length; i++) {
      hash = 31 * hash + name.charCodeAt(i);
    }
    var index = Math.abs(hash % this.colors.length);
    return this.colors[index];
  }

  /**
   * @name formatDate
   * @param {string} dateString Date in string.
   * @desc Formats date as per portal formats.
   * @return {string} formattedDate
   */
  formatDate(dateString) {
    const date = new Date(dateString);

    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    return monthNames[monthIndex] + ' ' + year;
  }

  /**
   * @param {string} moduleName
   * @desc Checks if the requested module is activated or not.
   * @return {boolean} isActivated
   */
  isModuleActivated = (moduleName) => {
    const moduleRoute = {
      home: '/home',
      careers: '/careers',
    };
    const { pathname } = window.location;
    if (pathname === moduleRoute[moduleName]) {
      return true;
    }
    return false;
  };

  /**
   * @name formatDateTime
   * @param {string} dateTimeString Date in string.
   * @desc Formats da tetime as per portal formats.
   * @return {string} formattedDatetime
   */
  formatDateTime(dateTimeString) {
    const date = new Date(dateTimeString);

    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    return date.getDate() + ' ' + monthNames[monthIndex] + ' ' + year + ' - ' + date.getHours() + ':' + date.getMinutes();
  }

  getLaunchUrl = async (resourceId) => {
    return await Http.REQUEST.get(`/resource/launch/${resourceId}`);
  };

  isRelativePath = (url) => {
    return url.startsWith(this._relativePathPrefix);
  };

  extractRelativePath = (url) => {
    const segments = url.split(this._relativePathPrefix);
    if (segments.length > 0) {
      return segments[segments.length - 1];
    }
    return url;
  };

  /**
   * @name numberWithCommas
   * @param {number} num number
   * @desc Formats number with commas
   * @return {string} comma separated number as string
   */
  static numberWithCommas = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  getCurrentDate = (format = 'LL') => moment().format(format);

  getAPIKey(code, type = Constant.ROUTES.BOOKMARKS) {
    if (!code || (typeof code !== 'string' && typeof code !== 'number')) return '';
    return type + '/' + code;
  }

  removeDuplicateObj = (objArr = [], key = 'bundleId') => {
    const keyValues = objArr.map((item) => [item[key], item]);
    return [...new Map(keyValues).values()];
  };

  /**
   * @name generateUniqueKey
   * @desc Generate unique key string
   * @return {string} unique string
   */
  generateUniqueKey = () => {
    return Math.random().toString(16).slice(2);
  };

  /**
   * @name getEnv
   * @param {string} envVarName Name of env. variable
   * @return string
   */
  static getEnv = (envVarName) => process.env[envVarName] ?? '';

  /**
   * @name sortByKey
   * @desc Sorts array of objects by key
   * @param {Array} arr
   * @param {String} key
   * @returns {Array} sorted array
   */
  static sortByKey = (arr, key) => {
    return (arr ?? []).sort((a, b) => {
      if (a[key] > b[key]) return 1;
      if (a[key] < b[key]) return -1;
      return 0;
    });
  };

  /**
   * @name parseError
   * @desc Parse error into string
   * @param {Array} errorArr
   * @returns {String} errorString
   */
  static parseError = (error) => {
    return error?.response?.data?.errorMessage && Array.isArray(error.response.data.errorMessage)
      ? error.response.data.errorMessage.join(', ')
      : 'Internal Server Error';
  };

  /**
   * @name transformDateTime
   * @desc Transforms datetime to user's timezone
   * @param {String} datetime
   * @returns {String}
   */
  static transformDateTime = (datetime, format = 'MMM D YYYY hh:mm A') => {
    const _utilityService = new Utility();
    const learnerTimezoneName = _utilityService.getTimezoneName(UserService.userServerTimestamp.timeZone).timezoneName;
    return _utilityService.parseTimeToNewZone(datetime, learnerTimezoneName, format);
  };

  /**
   * @name getFullName
   * @param {string} firstName First name
   * @param {string} lastName Last name
   * @return string
   */
  static getFullName = (firstName, lastName) => {
    return `${firstName || ''} ${lastName || ''}`.trim();
  };

  /**
   * @name handleEnterKey
   * @param {Object} event
   * @param {Function} callback
   * @desc Handles enter key press event
   * @returns {void}
   */
  static handleEnterKey = (event, callback) => {
    const enterKey = 'Enter';
    const enterKeyCode = 13;

    if (!event || !callback) return;

    if (event?.key === enterKey || event?.keyCode === enterKeyCode) {
      callback();
    }
  };

  /**
   * @name isNullOrEmpty
   * @param {Object} obj
   * @desc Checks if the object is null or empty
   * @returns {Boolean}
   */
  static isNullOrEmpty = (obj) => {
    // Check if the object is null or undefined
    if (obj === null || obj === undefined) {
      return true;
    }

    // Check if the object is an array and is empty
    if (Array.isArray(obj) && obj.length === 0) {
      return true;
    }

    // Check if the object is a string and is empty
    if (typeof obj === 'string' && obj.trim() === '') {
      return true;
    }

    // Check if the object is an object and has no own properties
    if (typeof obj === 'object' && Object.keys(obj).length === 0) {
      return true;
    }

    // Otherwise, return false
    return false;
  };

  static createSafeMarkup = (html) => {
    const sanitizedText = DOMPurify.sanitize(html);
    return {
      __html: sanitizedText.replace(/\n/g, '<br>'),
    };
  };
}
