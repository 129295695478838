import { MessagingService } from '../../../content';
import { Constant } from '../../../shared/services/constant/constant';
import { getAccessToken } from '../../../v2/core/auth/utils';
import { emitAnnouncementEvent } from '../../../v2/shared/services/utility/utility';

const { io } = require('socket.io-client');

let roomId = '';
let joined = false;
let socket;

const callbacks = {
  new_fnf_message: false,
  new_fnf_message_for_unread_count: false,
  new_staff_message: null,
};

let tokenInterval = null;

if (!Constant.POLL_CONFIG.USE_POLLING) {
  tokenInterval = setInterval(async () => {
    let token = await getAccessToken();

    if (token) {
      clearInterval(tokenInterval);
      establishSocketConnection(token);
    }
  }, 5000);
}

function establishSocketConnection(token) {
  socket = io(process.env.REACT_APP_MERCURY_URL, {
    extraHeaders: {
      Authorization: `Bearer ${token}`,
    },
  });

  socket.on('connect', function () {
    console.log(`Socket ${socket.id} CONNECTED`);
    joinRoom(socket.id);
  });

  socket.on('disconnect', function (reason) {
    console.log(`Socket ${socket.id} DISCONNECTED. Reason: ${reason}`);
    joined = false;
  });

  socket.on('connect_error', () => {
    setTimeout(() => {
      socket.connect();
    }, MessagingService.SOCKET_DEBOUNCE_INTERVAL);
  });

  socket.on('message', function (data) {
    console.log(data);
    if (callbacks[data.action]) {
      callbacks[data.action](data.payload);
    } else if (callbacks[`${data.action}_for_unread_count`]) {
      callbacks[`${data.action}_for_unread_count`](data.payload);
    }
  });
  socket.on('announcement', function (data) {
    emitAnnouncementEvent(data);
  });
}

export function registerAction(callback) {
  callbacks[callback.event] = callback.action;
}
export function removeAction(event) {
  callbacks[event] = false;
}

export function joinRoom(id) {
  if (!Constant.POLL_CONFIG.USE_POLLING) {
    roomId = id;
    if (joined) {
      return;
    }
    if (socket) {
      console.log(`roomId :${roomId}  Socket ${socket.id} CONNECTED`);
      socket.emit('message', {
        action: 'join_room',
        room_id: roomId,
      });
      joined = true;
    }
  }
}
