import { Http, store, toggleToast } from '../../../core';
import { Constant } from '../../../shared';

class ProgramConstants {
  /**
   * @name SECTION_TYPE
   * @desc ENUM for section types.
   * @return {Object}
   */
  static get SECTION_TYPE() {
    return {
      ACCORDION: 0,
      BUTTONABLE: 1,
    };
  }

  /**
   * @name FILTER_TRANSLATIONS
   * @desc ENUM for filter translations
   * @return {Object}
   */
  static get FILTER_TRANSLATIONS() {
    return {
      DURATION: {
        LONG_DURATION: 'longDuration',
        SHORT_DURATION: 'shortDuration',
        VARIES_DURATION: 'variesDuration',
        MEDIUM_DURATION: 'mediumDuration',
      },
      LANGUAGE: {
        LANGUAGE_EN: 'availableInEnglish',
        LANGUAGE_ES: 'availableInSpanish',
      },
      CERTIFICATE: {
        CERTIFICATE_AVAILABLE: 'certificateAvailable',
        CERTIFICATE_UNAVAILABLE: 'certificateUnavailable',
      },
    };
  }

  /**
   * @name ENROLLMENT_STATUS
   * @desc ENUM for different program enrollment statuses.
   * @return {Object}
   */
  static get ENROLLMENT_STATUS() {
    return {
      ENROLLED: 'ENROLLED',
      PENDING: 'PENDING',
      UNENROLLED: 'UNENROLLED',
    };
  }

  /**
   * @name TEMPLATE_TYPES
   * @desc ENUM for different program template types.
   * @return {Object}
   */
  static get TEMPLATE_TYPES() {
    return {
      TEMPLATE_SINGLE_COURSE: 'TEMPLATE_SINGLE_COURSE',
      TEMPLATE_MULTIPLE_COURSES_UNIFIED: 'TEMPLATE_MULTIPLE_COURSES_UNIFIED',
      TEMPLATE_MULTIPLE_COURSES_INDIVIDUAL: 'TEMPLATE_MULTIPLE_COURSES_INDIVIDUAL',
    };
  }

  /**
   * @name LEARNING_MODULE_PROGRESS
   * @desc ENUM for different learning module progress.
   * @returns {Object}
   */
  static get LEARNING_MODULE_PROGRESS() {
    return {
      NOT_STARTED: 'NOT STARTED',
      IN_PROGRESS: 'IN PROGRESS',
      COMPLETED: 'COMPLETED',
    };
  }
}

/**
 * @name getProgramDetails
 * @desc retrieve program details
 * @param {String} programId
 * @returns {Promise}
 */
const getProgramDetails = (programId) => {
  return new Promise((resolve, reject) => {
    Http.REQUEST.get(`/programs/v2/${programId}/details`).then((_successLog) => {
      resolve(_successLog);
    });
  });
};

/**
 * @name enrollIntoProgram
 * @desc enroll into program
 * @param {String} programId
 * @returns {Promise}
 */
const enrollIntoProgram = (programId) => {
  return new Promise((resolve, reject) => {
    Http.REQUEST.post(`/programs/${programId}/enroll`).then((_successLog) => {
      resolve(_successLog);
    });
  });
};

/**
 * @name unenrollFromProgram
 * @desc unenroll from program
 * @param {String} programId
 * @returns {Promise}
 */
const unenrollFromProgram = (programId) => {
  return new Promise((resolve, reject) => {
    Http.REQUEST.post(`/programs/${programId}/unenroll`).then((_successLog) => {
      resolve(_successLog);
    });
  });
};

/**
 * @name isEnrolled
 * @desc check if user is enrolled in program
 * @param {String} status
 * @returns {Boolean}
 */
const isEnrolled = (status) => {
  return status === ProgramConstants.ENROLLMENT_STATUS.ENROLLED;
};

/**
 * @name showDefaultErrorToast
 * @desc show default error toast
 */
const showDefaultErrorToast = () => {
  store.dispatch(
    toggleToast({
      variant: 'error',
      message: 'error.msg.default',
      title: 'sorry',
      isOpen: true,
      showCancelButton: true,
    })
  );
};

/**
 * @name showSuccessToast
 * @desc show success toast
 * @param {String} message
 * @param {String} values
 */
const showSuccessToast = (message, values) => {
  store.dispatch(
    toggleToast({
      variant: 'success',
      message,
      messageValues: values,
      title: 'success',
      isOpen: true,
      showCancelButton: true,
    })
  );
};

/**
 * @desc Prepares filters for course duration
 * @return {array}
 */
const getCourseDurationFilters = (programs) => {
  const filters = [];
  programs.forEach((program) => {
    program.tag.forEach((tag) => {
      if (Object.keys(ProgramConstants.FILTER_TRANSLATIONS.DURATION).indexOf(tag.tagName) > -1) {
        pushDataIntoFilter(filters, {
          filterKey: 'tag.tagId',
          type: 'array',
          filterValue: tag.tagId,
          translationKey: ProgramConstants.FILTER_TRANSLATIONS.DURATION[tag.tagName],
          checked: false,
        });
      }
    });
  });
  return filters;
};

/**
 * @param {*} param Filter parameters
 * @desc Filters data based on the filter parameters
 * @returns
 */
const filterData = ({ type, filterKey, filterValue }, item) => {
  filterKey = filterKey.split('.');
  try {
    if (type === 'array') {
      return (item[filterKey[0]] || []).some((tag) => tag[filterKey[1]] === filterValue);
    }
    return item[filterKey[0]][filterKey[1]] === filterValue;
  } catch (e) {
    return false;
  }
};

/**
 * @desc Prepares filters for getFilterBy
 * @return {array}
 */
const getFilterByFilters = (programs) => {
  const filters = [];
  programs.forEach((program) => {
    program.tag.forEach((tag) => {
      if (
        [
          ...Object.keys(ProgramConstants.FILTER_TRANSLATIONS.CERTIFICATE).filter(
            (key) => key !== Constant.TAGS_KEYS.CERTIFICATE_UNAVAILABLE
          ),
          ...Object.keys(ProgramConstants.FILTER_TRANSLATIONS.LANGUAGE),
        ].indexOf(tag.tagName) > -1
      ) {
        pushDataIntoFilter(filters, {
          filterKey: 'tag.tagId',
          type: 'array',
          filterValue: tag.tagId,
          translationKey:
            ProgramConstants.FILTER_TRANSLATIONS.CERTIFICATE[tag.tagName] ||
            ProgramConstants.FILTER_TRANSLATIONS.LANGUAGE[tag.tagName],
          checked: false,
        });
      }
    });
  });
  return filters;
};

/**
 * @desc Prepares filters for content provider
 * @return {array}
 */
const getContentProviderFilter = (programs) => {
  const filters = [];
  programs
    .filter((program) => Boolean(program.resourceProvider.thumbnailAltText))
    .forEach((program) => {
      pushDataIntoFilter(filters, {
        filterKey: 'resourceProvider.thumbnailAltText',
        type: 'object',
        filterValue: program.resourceProvider.thumbnailAltText,
        value: program.resourceProvider.thumbnailAltText,
        imageUrl: program.resourceProvider.thumbnailUriPath,
        checked: false,
      });
    });
  return filters;
};

/**
 * @desc Prepares filters for content provider
 * @return {array}
 */
const getProgramCategoryFilters = (programs) => {
  const filters = [];
  programs
    .filter((program) => Boolean(program.category))
    .forEach((program) => {
      let categoryName = program.category.name.toLowerCase();
      if (categoryName === 'personal development') {
        categoryName = 'Personal Growth';
      }
      pushDataIntoFilter(filters, {
        filterKey: 'category.id',
        type: 'object',
        filterValue: program.category.id,
        translationKey: categoryName,
        checked: false,
      });
    });
  return filters;
};

/**
 *
 * @param {*} filter
 * @param {*} data
 * @desc Pushes the data into filter only if it does NOT exists
 * @return void
 */
const pushDataIntoFilter = (filter, data) => {
  const existingData = filter.filter((item) => item.filterValue === data.filterValue);
  if (existingData.length === 0) {
    filter.push(data);

    filter = filter.sort((current, next) => {
      const value = current.translationKey || current.value;
      return value.localeCompare(next.translationKey || next.value);
    });
  }
};

export {
  ProgramConstants,
  getProgramDetails,
  enrollIntoProgram,
  unenrollFromProgram,
  isEnrolled,
  showDefaultErrorToast,
  showSuccessToast,
  getCourseDurationFilters,
  getProgramCategoryFilters,
  getContentProviderFilter,
  getFilterByFilters,
  filterData,
};
