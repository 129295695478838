import React from 'react';
import { Box, useTheme, withStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import styles from './program-selection.container.styles';
import { ExpandCollapseSections } from '../../../../shared';
import PropTypes from 'prop-types';
import ProgramSelection from '../../components/program-selection/program-selection';
import NavigationCard from '../../components/navigation-card/navigation-card';
import CareerCompassSummary from '../../components/summary/career-compass-summary';
import CareerCompassProgress from '../../components/career-compass-progress/career-compass-progress';
import TotalProgress from '../../components/total-progress/total-progress';
import { PillButton } from '../../../../../frontend-commons';
import withUnleash from '../../../../../core/components/unleash/withUnleash';
import { UnleashService } from '../../../../../core/services/unleash/unleash';
import { CAREER_COMPASS_CONSTANTS } from '../../services/career-compass.service';
import withCareerCompassAuth from '../career-compass/withCareerCompassAuth';
import useProgramSelectionContainerHook from './useProgramSelectionContainerHook';

const ProgramSelectionContainer = ({
  currentStep,
  summary,
  selectedLocale,
  handleCurrentStepUpdate,
  unleashProps,
  classes,
}) => {
  const theme = useTheme();
  const { expandCollapseSectionsRef, isFeatureFlagEnabled, expandTopSection, expandBottomSection, isNotFirstProgram } =
    useProgramSelectionContainerHook({ unleashProps, currentStep });

  return (
    <Box className={classes.container}>
      {isFeatureFlagEnabled && isNotFirstProgram ? (
        <ExpandCollapseSections
          ref={expandCollapseSectionsRef}
          topSection={{
            expanded: (
              <Box className={classes.boxHeightExpanded}>
                <ProgramSelection
                  currentStep={currentStep}
                  selectedLocale={selectedLocale}
                  handleCurrentStepUpdate={handleCurrentStepUpdate}
                />
              </Box>
            ),
            collapsed: (
              <NavigationCard
                title={<FormattedMessage id="career-compass.progress-program-selection-header" />}
                subtitle={<FormattedMessage id="career-compass.progress-program-selection-subtitle" />}
                backgroundColor={theme.palette.aqua.extraDeep}
                actionButton={
                  <PillButton
                    size="large"
                    variant="contained"
                    className={classes.pillButton}
                    aria-label="pick-program"
                    data-testid="pick-program"
                    onClick={expandTopSection}
                  >
                    <FormattedMessage id="pickProgram" />
                  </PillButton>
                }
              />
            ),
          }}
          bottomSection={{
            expanded: <CareerCompassSummary />,
            collapsed: (
              <CareerCompassProgress
                title={<FormattedMessage id="career-compass.progress.title" />}
                isExpanded={false}
                onOpen={expandBottomSection}
                progressElement={
                  <TotalProgress
                    progressHeading={<FormattedMessage id="career-compass.progress.heading" />}
                    programsCompleted={
                      <FormattedMessage
                        id="career-compass.programs-completed"
                        values={{ count: summary?.programsCompleted || 0 }}
                      />
                    }
                    isExpanded={false}
                  />
                }
              />
            ),
          }}
          defaultExpanded="bottom"
        />
      ) : (
        <Box className={classes.boxHeightCollapsed}>
          <ProgramSelection
            currentStep={currentStep}
            selectedLocale={selectedLocale}
            handleCurrentStepUpdate={handleCurrentStepUpdate}
          />
        </Box>
      )}
    </Box>
  );
};

ProgramSelectionContainer.propTypes = {
  currentStep: PropTypes.object,
  summary: PropTypes.shape({
    programsCompleted: PropTypes.number,
    summaryItems: PropTypes.arrayOf(
      PropTypes.shape({
        category: PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
        }),
        programs: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            title: PropTypes.string,
            isCompleted: PropTypes.bool,
            isStarted: PropTypes.bool,
          })
        ),
      })
    ),
  }),
  selectedLocale: PropTypes.string.isRequired,
  handleCurrentStepUpdate: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

const WrappedComponent = withUnleash(
  withStyles(styles)(ProgramSelectionContainer),
  UnleashService.FLAGS.ENABLE_CAREER_COMPASS_PROGRESS
);
export default withCareerCompassAuth(WrappedComponent, CAREER_COMPASS_CONSTANTS.PAGES.PROGRAM_SELECTION);
