import { makeStyles } from '@material-ui/core/styles';
import { Constant } from '../../../../shared/services';

const drawerWidth = Constant.STYLES.DRAWER_WIDTH;
const drawerCloseWidth = Constant.STYLES.DRAWER_CLOSE_WIDTH_V2;

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  topWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    zIndex: 99,
  },
  groupIcon: {
    fontSize: 30,
  },
  menuBtn: {
    position: 'absolute',
    display: 'block',
    left: 20,
    top: 0,
  },
  topLogoMain: {
    margin: '15px 20px',
    '& img': {
      display: 'block',
      maxWidth: 50,
    },
  },
  brandLogo: {
    width: 51,
    marginLeft: 5,
  },
  drawer: {
    background: theme.palette.primary.sub,
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  drawerPaper: {
    background: theme.palette.primary.sub,
    top: '80px',
    color: theme.palette.primary.textColor,
    overflowX: 'hidden',
    borderRight: 'none',
    width: drawerWidth,
    height: 'calc(100vh - 80px)',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  drawerOpen: {
    background: theme.palette.navy.main,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    background: theme.palette.navy.main,
    width: drawerCloseWidth,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  menuMainIcon: {
    fontSize: 40,
  },
  drawerHead: {
    padding: 15,
    maxHeight: 165,
    background: theme.palette.primary.main,
  },
  drawerHeadClose: {
    padding: '30px 0px !important',
  },
  drawerActionBtn: {
    color: theme.palette.primary.white,
  },
  drawMainGrid: {
    height: '100%',
    flexWrap: 'nowrap',
    flexDirection: 'column',
  },
  drawerContent: {
    flex: 1,
    padding: 0,
  },
  drawerContentList: {
    padding: 0,
    width: '100%',
  },
  drawerContentListItem: {
    minHeight: 48,
    width: drawerWidth,
    color: theme.palette.blue.greyed,
    fill: theme.palette.blue.greyed,
    stroke: theme.palette.blue.greyed,
    '&:focus': {
      color: theme.palette.primary.active,
      fill: theme.palette.primary.active,
      stroke: theme.palette.primary.active,
    },
  },
  drawerContentIcon: {
    color: 'inherit',
    minWidth: 60,
    minHeight: 48,
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      width: 16,
      height: 16,
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: 15,
    },
  },
  drawerContentText: {
    ...theme.typography.t16,
    ...theme.typography.bold,
    color: 'inherit',
    '&:first-letter': {
      textTransform: 'uppercase',
    },
  },
  drawerContentActive: {
    color: theme.palette.primary.active,
    fill: theme.palette.primary.active,
    stroke: theme.palette.primary.active,
    position: 'relative',
    backdropFilter: 'brightness(80%)',
    '&::before': {
      content: "''",
      borderRight: `4px solid ${theme.palette.primary.active}`,
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
    },
    '&::after': {
      content: '""',
      top: 0,
      left: 0,
      bottom: 0,
      width: '100%',
      position: 'absolute',
      opacity: 0.4,
    },
  },
  langActive: {
    color: '#0171ed !important',
  },
  langSelection: {
    cursor: 'pointer',
    display: 'flex',
    margin: '20px 0px',

    '& p': {
      color: theme.palette.primary.lightGrey,
      ...theme.typography.medium,
      marginRight: 10,
    },
    '& p:not(:first-child)': {
      borderLeft: `1px solid rgba(0, 0, 0, 0.12)`,
      paddingLeft: 10,
    },
  },
  expandCollapseBtn: {
    padding: 0,
    position: 'fixed',
    top: '160px',
    background: 'white',
    color: theme.palette.primary.sub,
    boxShadow: '0px 4px 8px 2px rgb(142 142 142 / 10%)',
    '&:hover': {
      background: 'white',
    },
  },
  collapseIconPosition: {
    left: `${drawerWidth - 20}px`,
    transition: theme.transitions.create('left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  expandIconPosition: {
    left: `${drawerCloseWidth - 20}px`,
    transition: theme.transitions.create('left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  userName: {
    textTransform: 'capitalize',
    color: 'white',
    ...theme.typography.t16,
    ...theme.typography.bold,
  },
  drawerWrapperV2: {
    color: theme.palette.primary.lightGrey,
    flex: 1,
  },
  signOutBtn: {
    margin: '20px 0px',
    textTransform: 'capitalize',
  },
  bgOverlayHome: {
    background: 'url(https://content.connectedcorrections.com/assets/img/ccfe-home-bg-1.jpg) no-repeat',
    backgroundPosition: 'center',
    backgroundSize: '200%',
    width: '100%',
    height: 150,
    position: 'absolute',
    zIndex: 2,
  },
  bgOverlayCareers: {
    background: 'url(https://content.connectedcorrections.com/assets/img/ccfe-careers-bg.jpg) no-repeat',
    backgroundPosition: 'center',
    backgroundSize: '200%',
    width: '100%',
    height: 150,
    position: 'absolute',
    zIndex: 2,
    opacity: 0.8,
  },
  expandCollapseBtnContainer: {
    position: 'relative',
    zIndex: 1300,
  },
  topArea: {
    minHeight: 48,
    width: drawerWidth,
    padding: '8px 0px 0px',
  },
  divider: {
    backgroundColor: theme.palette.blue.greyed,
    margin: '12px 16px',
  },
  textWithIconContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    paddingTop: 0,
    paddingBottom: 0,
    '& div': {
      minWidth: 60,
      minHeight: 48,
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
    },
    '& svg': {
      width: 16,
      height: 16,
      justifyContent: 'center',
      alignItems: 'center',
    },
    '&:focus': {
      '& p': {
        color: theme.palette.primary.active,
      },
      '& svg': {
        fill: theme.palette.primary.active,
      },
    },
  },
  gutterRight: {
    marginRight: 15,
  },
  myProfileIcon: {
    color: theme.palette.primary.white,
  },
  myProfileIconActive: {
    color: theme.palette.primary.active,
  },
}));
