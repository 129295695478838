import axios from 'axios';
import { store, showHideLoader, Keycloak } from '../../';
import { showSessionExpirePage, toggleToast } from '../../store';
import { Constant, UserService, Utility } from '../../../shared';

import { removePendingAPIKey, addPendingAPIKey } from '../../store/pending-APIs';
import { getAccessToken, getUseOktaAuth0, handleLogout } from '../../../v2/core/auth/utils';
import { AUTH_CONSTANTS } from '../../../v2/core/auth/constants/auth-constants';
import { logoutFromPartnerSystems } from '../../../v2/shared/services/partner-logout/partner-logout.service';

export class Http {
  ROUTES = Constant.ROUTES;
  userService = new UserService();
  utilityService = new Utility();
  static requestCntr = 0;
  static REQUEST = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
  });
  static HERMES_REQUEST = axios.create({
    baseURL: process.env.REACT_APP_HERMES_URL,
  });
  static HYREUS_REQUEST = axios.create({
    baseURL: process.env.REACT_APP_HYREUS_URL,
  });
  static axios = axios;

  constructor() {
    this.handleRequest();
    this.handleResponse();
  }

  /**
   * @name refreshToken
   * @desc method for refreshing keycloak token.
   * @return {void}
   */
  refreshToken() {
    let expiryDateTime = new Date();
    let currentDateTime = new Date();

    if (Keycloak.Keycloak.tokenParsed && Keycloak.Keycloak.tokenParsed.exp) {
      expiryDateTime = new Date(Keycloak.Keycloak.tokenParsed.exp * 1000);
    }
    let diffInMs = expiryDateTime - currentDateTime;
    let diffInMins = Math.round(((diffInMs % 86400000) % 3600000) / 60000);
    if (diffInMins <= 5) {
      Keycloak.Keycloak.updateToken(9999)
        .then(function (refreshed) {
          // token refreshed or else token valid
        })
        .catch(function (e) {
          console.log('Failed to refresh the token, or the session has expired', e);
        });
    }
  }

  /**
   * @name addRemovePendingURLKey
   * @param {object}  url = "", method = "", data = {}
   * @desc this fired upon every request and response and check the bookmark pending API key to display/hide loader.
   * @return null
   */
  addRemovePendingURLKey(obj = {}, action = 'ADD') {
    try {
      const { pendingApiKey } = obj;
      if (pendingApiKey) {
        if (action === 'ADD') store.dispatch(addPendingAPIKey({ key: pendingApiKey }));
        else if (action === 'REMOVE') store.dispatch(removePendingAPIKey({ key: pendingApiKey }));
      }
    } catch (error) {
      console.log(`error:::`, error);
    }
  }

  /**
   * @name requestHandler
   * @param {object} request
   * @desc Callback being fired upon every request.
   * @return {object} modifedRequest
   */
  async requestHandler(request) {
    if (!getUseOktaAuth0()) {
      this.refreshToken();
    }

    const { app } = store.getState();

    // Modify request headers
    request.headers['Content-Type'] = 'application/json';
    request.headers['Authorization'] = 'Bearer ' + (await getAccessToken());
    request.headers['Accept-Language'] = app.locale ? app.locale.toLowerCase() : `en`;

    this.addRemovePendingURLKey(request, 'ADD');
    // Http.requestCntr++;
    // initiate loader display
    if (request.url.indexOf('noLoader=true') === -1) {
      this.toggleLoader('show');
    }

    return request;
  }

  /**
   * @name toggleLoader
   * @param {string} type Action type of loader
   * @desc Toggles loader based on requestCntr condition and type of action required.
   * @return {void}
   */
  toggleLoader = (type) => {
    switch (type) {
      case 'show':
        Http.requestCntr++;
        store.dispatch(showHideLoader({ isLoading: true }));
        break;

      case 'hide':
        Http.requestCntr--;
        if (Http.requestCntr <= 0) {
          Http.requestCntr = 0;
          store.dispatch(showHideLoader({ isLoading: false }));
        }
        break;

      default:
        return;
    }
  };

  /**
   * @name errorHandler
   * @param {object} error
   * @desc Callback being fired when error occurs while making HTTP call.
   * @return {object} error
   */
  async errorHandler(error) {
    if (error.response) {
      try {
        if (error.response.status === 403 || error.response.status === 401) {
          store.dispatch(showSessionExpirePage({ openSessionExpire: true }));
          await logoutFromPartnerSystems();
        }
      } catch (e) {
        console.log(e);
      }

      // dissolve loader display
      if (error.response && error.response.config) {
        this.addRemovePendingURLKey(error.response.config, 'REMOVE');
      }
      // dissolve loader display
      if (error.response && error.response.config.url.indexOf('noLoader=true') === -1) {
        this.toggleLoader('hide');
      }
    } else if (axios.isCancel(error)) {
      throw error;
    } else if (error.message === AUTH_CONSTANTS.AUTH_ERROR_TYPES.USER_LOGGED_OUT) {
      await logoutFromPartnerSystems(false);
      handleLogout(Keycloak.Keycloak);
    } else {
      store.dispatch(
        toggleToast({
          isOpen: true,
          variant: 'error',
          message: 'error.msg.default',
          title: 'sorry',
          showCancelIcon: true,
        })
      );
      Http.requestCntr = 0;
      store.dispatch(showHideLoader({ isLoading: false }));
    }

    return Promise.reject({ ...error });
  }

  /**
   * @name successHandler
   * @param {object} response
   * @desc Callback being fired when HTTP request passes with success state.
   * @return {object}
   */
  successHandler(response) {
    this.addRemovePendingURLKey(response.config, 'REMOVE');
    if (response.config.url.indexOf('noLoader=true') === -1) {
      this.toggleLoader('hide');
    }
    return response;
  }

  /**
   * @name handleRequest
   * @desc Wrapper method handling all requests.
   * @return {void}
   */
  handleRequest() {
    Http.REQUEST.interceptors.request.use((request) => this.requestHandler(request));

    Http.HERMES_REQUEST.interceptors.request.use((request) => this.requestHandler(request));

    Http.HYREUS_REQUEST.interceptors.request.use((request) => this.requestHandler(request));
  }

  /**
   * @name handleResponse
   * @desc Wrapper method handling all response.
   * @return {void}
   */
  handleResponse() {
    Http.REQUEST.interceptors.response.use(
      (response) => this.successHandler(response),
      (error) => this.errorHandler(error)
    );

    Http.HERMES_REQUEST.interceptors.response.use(
      (response) => this.successHandler(response),
      (error) => this.errorHandler(error)
    );

    Http.HYREUS_REQUEST.interceptors.response.use(
      (response) => this.successHandler(response),
      (error) => this.errorHandler(error)
    );
  }

  /**
   * @name fetchRequest
   * @param {string} url
   * @param {object} payload
   * @desc Wrapper fetch method.
   * @return {promise}
   */
  static async fetchRequest(url, payload) {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}${url}`, {
      keepalive: true,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(payload),
    });
    return response.json();
  }
}
