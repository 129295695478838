import * as store from './';

export const setCareerCompassCurrentStep = (currentStep) => {
  return {
    type: store.SET_CAREER_COMPASS_CURRENT_STEP,
    currentStep,
  };
};

export const setCareerCompassSummary = (summary) => {
  return {
    type: store.SET_CAREER_COMPASS_SUMMARY,
    summary,
  };
};

export const setCareerCompassSummaryLoading = (isSummaryLoading) => {
  return {
    type: store.SET_CAREER_COMPASS_SUMMARY_LOADING,
    isSummaryLoading,
  };
};

export const setSelectedProgram = (program) => {
  return {
    type: store.SET_SELECTED_PROGRAM,
    program,
  };
};
