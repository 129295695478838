import React from 'react';
import { Box, Button, CircularProgress, List, ListItem, ListItemIcon, withStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import styles from './message-sidebar.style';
import { FormattedMessage, useIntl } from 'react-intl';
import { EnvelopeSolidIcon, LoudSpeakerIcon } from '../../../../shared/icons';
import useMessageSidebarHook from './useMessageSidebarHook';
import { connect } from 'react-redux';
import { EmptyInbox, MessageThreads } from '..';
import MarkRead from '../mark-read/mark-read';
import { UnleashService } from '../../../../core/services/unleash/unleash';
import withUnleash from '../../../../core/components/unleash/withUnleash';

const MessageSidebar = (props) => {
  const {
    contacts,
    isLazyLoading,
    unreadMessageThreadsCount,
    unreadAnnouncementThreadsCount,
    getThreads,
    getTotalThreads,
    getUnreadMessagesThreadCount,
    getUnreadAnnouncementsThreadCount,
    isActiveModeMessages,
    isActiveModeAnnouncements,
    activateMessagesMode,
    activateAnnouncementsMode,
    isInboxEmpty,
    shouldShowNewMessageButton,
    isDataLoading,
    loadMoreThreads,
    isThreadSelected,
    handleThreadClick,
    markAllAnnouncementRead,
    markAllMessagesRead,
  } = useMessageSidebarHook(props);
  const { classes, openComposeMessageView, unleashProps } = props;
  const unreadMessageThreadCount = getUnreadMessagesThreadCount();
  const unreadAnnouncementThreadCount = getUnreadAnnouncementsThreadCount();
  const intl = useIntl();

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <List className={classes.tabsList}>
          <ListItem
            button
            data-cy-name="message-inbox"
            id="message-inbox"
            onClick={activateMessagesMode}
            className={[classes.tabItem, isActiveModeMessages() ? classes.activeItem : ''].join(' ')}
            aria-label={intl.formatMessage({ id: 'inbox' })}
          >
            <ListItemIcon className={classes.icon}>
              {Boolean(unreadMessageThreadCount) && <Box id="messages-red-dot" className={classes.redDot} />}
              <EnvelopeSolidIcon />
            </ListItemIcon>
            <FormattedMessage id="inbox" /> {unreadMessageThreadCount}
          </ListItem>
          <ListItem
            button
            data-cy-name="announcement-inbox"
            id="announcement-inbox"
            onClick={activateAnnouncementsMode}
            className={[classes.tabItem, isActiveModeAnnouncements() ? classes.activeItem : ''].join(' ')}
            aria-label={intl.formatMessage({ id: 'announcements' })}
          >
            <ListItemIcon className={classes.icon}>
              {Boolean(unreadAnnouncementThreadCount) && <Box id="announcements-red-dot" className={classes.redDot} />}
              <LoudSpeakerIcon />
            </ListItemIcon>
            <FormattedMessage id="announcements" /> {unreadAnnouncementThreadCount}
          </ListItem>
        </List>
        {unleashProps.isFlagEnabled() && (
          <MarkRead
            messageCount={unreadMessageThreadsCount}
            announcementCount={unreadAnnouncementThreadsCount}
            isMessageActive={isActiveModeMessages()}
            isAnnouncementActive={isActiveModeAnnouncements()}
            markAllMessagesReadLocally={markAllMessagesRead}
            markAllMessagesAnnouncementLocally={markAllAnnouncementRead}
          />
        )}
      </Box>
      <Box className={classes.body}>
        {isDataLoading() ? (
          <Box className={classes.loaderContainer}>
            <CircularProgress />
          </Box>
        ) : isInboxEmpty() ? (
          <EmptyInbox
            isActiveModeMessages={isActiveModeMessages}
            isActiveModeAnnouncements={isActiveModeAnnouncements}
            contactsCount={contacts.length}
          />
        ) : (
          <MessageThreads
            threads={[...getThreads()]}
            totalThreads={getTotalThreads()}
            loadMoreThreads={loadMoreThreads}
            isThreadSelected={isThreadSelected}
            handleThreadClick={handleThreadClick}
            isActiveModeAnnouncements={isActiveModeAnnouncements()}
            isLoading={isLazyLoading}
          />
        )}
      </Box>

      {shouldShowNewMessageButton() && (
        <Box className={classes.footer}>
          <Button
            id="new-message-button"
            variant="contained"
            color="primary"
            className={classes.newMessageButton}
            startIcon={<AddIcon />}
            onClick={openComposeMessageView}
            aria-label={intl.formatMessage({ id: 'newMessage' })}
          >
            <FormattedMessage id="newMessage" />
          </Button>
        </Box>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  staffMessaging: state.staffMessaging,
  app: state.app,
});

export default withUnleash(
  connect(mapStateToProps)(withStyles(styles)(MessageSidebar)),
  UnleashService.FLAGS.MARK_MESSAGES_ANNOUNCEMENTS_READ
);
