export default (theme) => ({
  root: {
    borderRadius: 12,
    '&.MuiAccordion-rounded:last-child': {
      borderRadius: 12,
    },
    '&.MuiAccordion-root': {
      '&:before': {
        display: 'none',
      },
      '& .MuiAccordionSummary-root': {
        flexDirection: 'row-reverse', // This is to make the expand icon appear on the left side
      },
      '& .MuiAccordionSummary-content': {
        margin: '12px 0',
      },
      '& .MuiDivider-root': {
        margin: '0 12px',
      },
    },
  },
  activeModule: {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.pale,
    '& $title': {
      color: theme.palette.blue.primary,
    },
    '& .MuiAccordionSummary-expandIcon svg': { color: theme.palette.blue.primary },
  },
  disabledModule: {
    border: `1px solid ${theme.palette.greyScale[70]}`,
    backgroundColor: theme.palette.greyScale[20],
  },
  divider: {
    backgroundColor: theme.palette.greyScale[30],
  },
  textArea: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    flex: '1 0 0',
  },
  noUnits: {
    marginLeft: '36px',
  },
  title: {
    fontSize: 16,
    fontWeight: 700,
    textTransform: 'capitalize',
    color: theme.palette.greyScale[110],
  },
  subtitle: {
    color: theme.palette.greyScale[110],
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  circularButton: {
    borderRadius: '100px',
    border: `1px solid ${theme.palette.blue.primary}`,
    backgroundColor: 'transparent',
    color: theme.palette.blue.primary,
    width: '40px',
    height: '40px',
  },
  launchButton: {
    marginLeft: theme.spacing(1),
  },
  unitsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    width: '100%',
    '& hr': {
      margin: '0 0 0 52px !important',
    },
  },
});
