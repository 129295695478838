import React from 'react';
import PropTypes from 'prop-types';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography, withStyles } from '@material-ui/core';
import styles from './career-compass-summary-section.styles';
import { FormattedMessage } from 'react-intl';
import { Chip } from '@orijinworks/frontend-commons';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

/**
 * Component that renders the Career Compass Summary Item
 *
 * @param {object} props - The component props
 * @returns  {JSX.Element}
 */
const CareerCompassSummaryItem = ({ classes, category, programs }) => {
  return (
    <Box className={classes.root}>
      <Typography data-testid="category-name" variant="h3" color="secondary">
        {category}
      </Typography>
      {programs?.length > 0 && (
        <Table data-testid="programs-table" className={classes.table} aria-label={`Programs in ${category} category`}>
          <TableHead>
            <TableRow className={classes.tableHeadRow}>
              <TableCell className={classes.tableCell}>
                <Typography data-testid="program-header" color="secondary" className={classes.bold}>
                  <FormattedMessage id="program" />
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {programs.map((program) => (
              <TableRow key={program.id} className={classes.tableBodyRow}>
                <TableCell className={classes.tableCell}>
                  <Typography data-testid="program-name" className={classes.tableBodyText}>
                    {program.title}
                  </Typography>
                  {program.isCompleted ? (
                    <Chip
                      data-testid="completed-chip"
                      size="small"
                      label={<FormattedMessage id="complete" />}
                      variant="outlined"
                      icon={<FiberManualRecordIcon className={classes.completedChipIcon} />}
                      classes={{
                        root: classes.chip,
                        outlined: classes.completedChip,
                      }}
                    />
                  ) : (
                    program.isStarted && (
                      <Chip
                        data-testid="in-progress-chip"
                        size="small"
                        label={<FormattedMessage id="inProgress" />}
                        variant="outlined"
                        icon={<FiberManualRecordIcon className={classes.inProgressChipIcon} />}
                        classes={{
                          root: classes.chip,
                          outlined: classes.inProgressChip,
                        }}
                      />
                    )
                  )}
                </TableCell>
              </TableRow>
            ))}
            <TableRow className={`${classes.tableBodyRow} ${classes.lastTableBodyRow}`}>
              <TableCell className={classes.tableCell}>
                <Typography data-testid="more-to-come-text" className={`${classes.tableBodyText} ${classes.bold}`}>
                  <FormattedMessage id="career-compass.summary.program.more-to-come" />
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </Box>
  );
};

CareerCompassSummaryItem.propTypes = {
  /**
   * The classes object to use for styling that will be injected by withStyles.
   */
  classes: PropTypes.object.isRequired,
  /**
   * The category of the programs.
   */
  category: PropTypes.string.isRequired,
  /**
   * The programs to display in the summary.
   */
  programs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      title: PropTypes.string,
      isCompleted: PropTypes.bool,
      isStarted: PropTypes.bool,
    })
  ).isRequired,
};

export default withStyles(styles)(CareerCompassSummaryItem);
