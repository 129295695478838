export default (theme) => ({
  container: {
    margin: '24px 24px 24px 101px',
  },
  boxHeightExpanded: {
    height: 'calc(100dvh - 275px)',
  },
  boxHeightCollapsed: {
    height: 'calc(100dvh - 136px)',
  },
  pillButton: {},
  pickProgramButton: {
    backgroundColor: `${theme.palette.navy.main} !important`,
  },
  chip: {
    color: theme.palette.common.white,
    textAlign: 'center',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    letterSpacing: '0.32px',
    borderRadius: '8px',
    backgroundColor: theme.palette.aqua.extraDeep,
    textTransform: 'capitalize',
  },
});
