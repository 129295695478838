import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { CareersService } from '../../services/careers.service';
import { InterestProfilerService } from '../../../interest-profiler/services/interest-profiler.service';
import { default as CareerDirectoryView } from './view';
import { View as CareerDirectoryView2 } from '../../../../v2/content/careers/containers/';
import { UnleashService, UnleashProvider } from '../../../../core';
import { CAREER_DIRECTORY_FILTERS } from './constants';

export class CareerDirectory extends React.Component {
  _service = new CareersService();
  _interetProfilerService = new InterestProfilerService();
  constructor(props) {
    super(props);
    this.state = {
      careers: [],
      searchValue: '',
      selectedZone: `${props.interestProfiler.recommendedJobZone}` || '1',
      page: 0,
      pageSize: 10,
      total: 0,
      searchQueryCalled: false,
      ipResultsFetched: false,
      ipResults: {},
      showPersonalityDetails: false,
      selectedView: 'detailed',
      mainFilter: CAREER_DIRECTORY_FILTERS.ALL,
      selectedCategory: '',
      selectedGroup: 'A - F',
      allMatchingCareers: [],
      categories: [],
      alphabeticalGroups: [],
    };
    this.scrollRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.interestProfiler.answersString) {
      this.fetchInterestProfilerResults();
      this.setState({ ipResultsFetched: true, mainFilter: CAREER_DIRECTORY_FILTERS.BEST_FIT }, () => {
        this.getCareerDirectory();
      });
    } else {
      this.getCareerDirectory();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedLocale !== prevProps.selectedLocale) {
      this.getCareerDirectory();
    }
    if (this.props.interestProfiler.recommendedJobZone !== prevProps.interestProfiler.recommendedJobZone) {
      this.setState(
        {
          selectedZone: `${this.props.interestProfiler.recommendedJobZone}`,
          page: 0,
          searchValue: '',
        },
        () => {
          this.getCareerDirectory();
        }
      );
    }
    if (this.props.interestProfiler.answersString && !this.state.ipResultsFetched) {
      this.fetchInterestProfilerResults();
      this.setState({ ipResultsFetched: true, mainFilter: CAREER_DIRECTORY_FILTERS.BEST_FIT });
    }
  }

  fetchInterestProfilerResults = () => {
    this._interetProfilerService
      .getInterestProfilerResults(this.props.interestProfiler.answersString)
      .then((data) => {
        this.setState({ ipResults: data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getCareerDirectory = async () => {
    try {
      const { searchValue, selectedZone, page, pageSize, selectedCategory, selectedGroup, mainFilter } = this.state;

      this._service
        .getCareerClusters()
        .then((response) => {
          const { payload } = response;
          this.setState({ categories: payload.clusters });
        })
        .catch((error) => {
          console.log(error);
        });

      this._service
        .getAlphabeticalGroups()
        .then((response) => {
          const { payload } = response;
          this.setState({ alphabeticalGroups: payload.alphabeticalGroups });
        })
        .catch((error) => {
          console.log(error);
        });

      if (mainFilter === CAREER_DIRECTORY_FILTERS.ALL) {
        const data = await this._service.getCareerDirectory(
          page * pageSize,
          pageSize,
          searchValue,
          selectedCategory,
          selectedGroup
        );
        if (data && data.payload) {
          const { careers, total } = data.payload;
          if (careers && careers.length >= 0) {
            this.setState({ careers, total, searchQueryCalled: !!searchValue });
          }
        }
      } else {
        if (page === 0) {
          const data = await this._service.getMatchingCareers(
            page * pageSize,
            50,
            searchValue,
            selectedZone,
            selectedCategory,
            this.props.interestProfiler.answersString
          );
          if (data && data.payload) {
            const { careers, total } = data.payload;
            if (careers && careers.length >= 0) {
              const firstPageCareers = [...careers].slice(0, pageSize);
              this.setState({
                allMatchingCareers: careers,
                careers: firstPageCareers,
                total,
                searchQueryCalled: !!searchValue,
              });
            }
          }
        } else {
          const { allMatchingCareers } = this.state;
          const pageCareers = [...allMatchingCareers].slice(page * pageSize, (page + 1) * pageSize);
          this.setState({ careers: pageCareers });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  getJobZone = (career) => {
    let zone = 1;
    if (career.education && career.education.job_zone) {
      zone = career.education.job_zone;
    }
    return zone;
  };

  getMedianPay = (career) => {
    let data = {};
    if (career.job_outlook && career.job_outlook.salary) {
      if (career.job_outlook.salary.annual_median) {
        data = {
          medianPay: career.job_outlook.salary.annual_median,
          medianPayLabel: 'annualMedian',
        };
      } else if (career.job_outlook.salary.annual_median_over) {
        data = {
          medianPay: career.job_outlook.salary.annual_median_over,
          medianPayLabel: 'annualMedian',
        };
      } else if (career.job_outlook.salary.hourly_median) {
        data = {
          medianPay: career.job_outlook.salary.hourly_median,
          medianPayLabel: 'hourlyMedian',
        };
      } else {
        data = {
          medianPay: career.job_outlook.salary.hourly_median_over || 30,
          medianPayLabel: 'hourlyMedian',
        };
      }
    }
    return data;
  };

  handlePageChange = (e, value) => {
    this.setState({ page: value - 1 }, () => {
      this.getCareerDirectory();
      this.scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    });
  };

  handleSearch = (e) => {
    const value = e.target.value;
    this.setState({ searchValue: value });
  };

  onSearch = (searchText = '') => {
    this.setState({ page: 0, searchValue: searchText }, () => {
      this.getCareerDirectory();
    });
  };

  handleZoneFilter = (value) => {
    this.setState({ selectedZone: value, page: 0 }, () => {
      this.getCareerDirectory();
    });
  };

  handleGroupFilter = (value) => {
    this.setState({ selectedGroup: value, page: 0 }, () => {
      this.getCareerDirectory();
    });
  };

  onCategoryChange = (value) => {
    this.setState({ selectedCategory: value, page: 0 }, () => {
      this.getCareerDirectory();
    });
  };

  onItemClick = (e, id) => {
    e.stopPropagation();
    this.props.history.push(`/careers/${encodeURIComponent(id)}/profile`);
  };

  openPersonalityDetailsDialog = () => {
    this.setState({ showPersonalityDetails: true });
  };

  closePersonalityDetailsDialog = () => {
    this.setState({ showPersonalityDetails: false });
  };

  onClearFilters = () => {
    this.setState({ searchValue: '', selectedCategory: '' }, () => {
      this.getCareerDirectory();
    });
  };

  handleViewChange = (view) => {
    this.setState({ selectedView: view });
  };

  setMainFilter = (filter) => {
    this.setState(
      {
        mainFilter: filter,
        page: 0,
        selectedGroup: 'A - F',
        selectedZone: `${this.props.interestProfiler.recommendedJobZone}` || '1',
      },
      () => {
        this.getCareerDirectory();
      }
    );
  };

  render() {
    const enabledRenderFn = () => {
      return <CareerDirectoryView2 parent={this} />;
    };

    const disabledRenderFn = () => {
      return <CareerDirectoryView parent={this} handleSearch={this.handleSearch} />;
    };

    return (
      <UnleashProvider
        flagName={UnleashService.FLAGS.CAREER_DIRECTORY_UPDATE}
        enabledRenderFn={enabledRenderFn}
        disabledRenderFn={disabledRenderFn}
      />
    );
  }
}

const mapStateToProps = ({ interestProfiler }) => {
  return {
    interestProfiler,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CareerDirectory);
