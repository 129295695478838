import React from 'react';
import {
  withStyles,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import IdleTimer from 'react-idle-timer';
import { useLocation } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Keycloak } from '../../../core';
import { Utility, Constant, UserService } from '../../../shared';

import styles from './idle-timeout.style';
import { handleLogout } from '../../../v2/core/auth/utils';
import { useAuth0 } from '@auth0/auth0-react';
import { logoutFromPartnerSystems } from '../../../v2/shared/services/partner-logout/partner-logout.service';

let timerInterval = null;
function clearTimerInterval() {
  if (timerInterval) {
    clearInterval(timerInterval);
  }
}

const IdleTimeout = (props) => {
  const { classes } = props;
  const [timer, setTimer] = React.useState(30);
  const [isOpen, setIsOpen] = React.useState(false);
  const [idleTimeout, setIdleTimeout] = React.useState(Constant.IDLE_TIMEOUT * 60000);
  const idleTimer = React.useRef();
  const urlLocation = useLocation();
  const userService = new UserService();
  const intl = useIntl();
  const { logout } = useAuth0();

  React.useEffect(() => {
    const currentPath = urlLocation.pathname;
    setIdleTimeout(Constant.IDLE_TIMEOUT * 60000);
    if (currentPath === '/entertain/video') {
      setIdleTimeout(Constant.VIDEO_IDLE_TIMEOUT * 60000);
    }
  }, [urlLocation]);

  React.useEffect(() => {
    timerInterval = setInterval(() => {
      if (!isOpen) {
        return;
      }
      console.log({ timerInitiated: timer });

      if (timer > 0) {
        setTimer(timer - 1);
      }
      if (timer === 0) {
        handleConfirm();
      }
    }, 1000);
    return () => {
      clearTimerInterval();
    };
  });

  const handleConfirm = async () => {
    clearTimerInterval();
    Utility.manageLogout('set');
    await logoutFromPartnerSystems();
    handleLogout(Keycloak.Keycloak, { logout });
  };

  const handleClose = () => {
    setIsOpen(false);
    setTimer(30);
  };

  const handleOnIdle = () => {
    setIsOpen(true);
  };

  return (
    <div>
      <IdleTimer ref={idleTimer} timeout={idleTimeout} onIdle={handleOnIdle} />
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
      >
        <DialogTitle id="alert-dialog-title" className={classes.titleContainer}>
          <Typography color="secondary" variant="h3" className={classes.title}>
            <FormattedMessage id="idleTimeoutTitle" values={{ timer }} />
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography color="secondary" className={classes.description}>
              <FormattedMessage
                id="messageIdleTimeout"
                values={{
                  idleTimeout: idleTimeout / 60000,
                  timer,
                }}
              />
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.actionsContainer}>
          <Button
            onClick={handleClose}
            color="primary"
            variant="contained"
            className={classes.actionBtn}
            aria-label={intl.formatMessage({ id: 'continueSession' })}
          >
            <FormattedMessage id="continueSession" />
          </Button>
          <Button
            onClick={handleConfirm}
            color="primary"
            variant="outlined"
            className={classes.actionBtn}
            aria-label={intl.formatMessage({ id: 'logout' })}
          >
            <FormattedMessage id="logout" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(IdleTimeout);
