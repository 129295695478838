import React from 'react';
import { Box, Typography, Modal, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { ContactRequestConstants } from '../../services';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { withStyles } from '@material-ui/core/styles';
import styles from './status-modal.style';
import useStatusModalHook from './useStatusModalHook';
import {
  CheckmarkIcon,
  ContactRequestBlockedIcon,
  CallErrorIcon,
  CallEndedIcon,
  CallRestrictedIcon,
} from '../../../../shared';
import { FormattedMessage } from 'react-intl';
import { Button } from '@material-ui/core';
const StatusModal = ({ classes, status, description, isStatusModalOpen, handleStatusModalClose }) => {
  const { getStatusClassName } = useStatusModalHook({ status, classes });
  const statusClassName = classes[getStatusClassName(status.toLowerCase())];

  const getStatusInfo = (status, description) => {
    const defaultInfo = { heading: '', icon: null, desc: null };

    switch (status) {
      case ContactRequestConstants.CONTACT_REQUEST_STATUSES.REJECTED:
        return {
          heading: 'Request Rejected',
          icon: <RemoveCircleIcon className={statusClassName} style={{ fontSize: '2.3rem' }} />,
          desc: description === '' ? <FormattedMessage id="contactRequestRejected" /> : description,
        };
      case ContactRequestConstants.CONTACT_REQUEST_STATUSES.BLOCKED:
        return {
          heading: 'Request Blocked',
          icon: <ContactRequestBlockedIcon className={statusClassName} style={{ fontSize: '2.3rem' }} />,
          desc: description === '' ? <FormattedMessage id="contactRequestBlocked" /> : description,
        };
      case ContactRequestConstants.CONTACT_REQUEST_STATUSES.PENDING:
        return {
          heading: 'Request Sent!',
          icon: <CheckmarkIcon className={statusClassName} fill="#66bb6a" style={{ fontSize: '2.3rem' }} />,
          desc: <FormattedMessage id="contactRequestSent" />,
        };
      case ContactRequestConstants.CONTACT_REQUEST_STATUSES.APPROVED:
        return {
          heading: 'Request Approved!',
          icon: <CheckmarkIcon className={statusClassName} fill="#66bb6a" style={{ fontSize: '2.3rem' }} />,
          desc: <FormattedMessage id="contactRequestApproved" />,
        };
      case ContactRequestConstants.CONTACT_CONNECTION_STATUSES.FAILED:
        return {
          heading: 'We were unable to connect your call',
          icon: <CallErrorIcon className={statusClassName} style={{ fontSize: '2.3rem' }} />,
          desc: description === '' ? <FormattedMessage id="contactRequestFailed" /> : description,
        };
      case ContactRequestConstants.CONTACT_CONNECTION_STATUSES.TERMINATED:
        return {
          heading: 'Call Was Ended By An Administrator',
          icon: <CallEndedIcon className={statusClassName} style={{ fontSize: '2.3rem' }} />,
          desc: description === '' ? <FormattedMessage id="callTerminatedDescription" /> : description,
        };
      case ContactRequestConstants.CONTACT_CONNECTION_STATUSES.RESTRICTED:
        return {
          heading: 'The call center is closed',
          icon: <CallRestrictedIcon className={statusClassName} style={{ fontSize: '2.3rem' }} />,
          desc: description === '' ? <FormattedMessage id="callRestrictedDescription" /> : description,
        };
      default:
        return defaultInfo;
    }
  };

  const modalInfo = getStatusInfo(status, description);

  return (
    <Box>
      {isStatusModalOpen && status !== ContactRequestConstants.CONTACT_CONNECTION_STATUSES.RESTRICTED && (
        <Modal open={isStatusModalOpen} onClose={handleStatusModalClose}>
          <Box className={classes.modal}>
            {modalInfo.icon}
            <Typography id="heading" variant="h3" className={`${classes.heading} ${statusClassName}`}>
              {modalInfo.heading}
            </Typography>
            <Typography id="description" variant="body1" className={classes.paragraph}>
              {modalInfo.desc}
            </Typography>
            <Button className={`${classes.dismissBtn}`} variant="contained" onClick={handleStatusModalClose}>
              <FormattedMessage id="dismissStatus" />
            </Button>
          </Box>
        </Modal>
      )}

      {isStatusModalOpen && status === ContactRequestConstants.CONTACT_CONNECTION_STATUSES.RESTRICTED && (
        <Modal open={isStatusModalOpen} onClose={handleStatusModalClose}>
          <Box className={classes.modal}>
            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <CallRestrictedIcon className={statusClassName} style={{ fontSize: '2.3rem' }} />
              <IconButton onClick={handleStatusModalClose} className={classes.closeButton} aria-label="close">
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
            <Typography id="heading" variant="h3" className={`${classes.headingRestricted} ${statusClassName}`}>
              {modalInfo.heading}
            </Typography>
            <Typography id="description" variant="body1" className={classes.paragraphRestricted}>
              {modalInfo.desc}
            </Typography>
          </Box>
        </Modal>
      )}
    </Box>
  );
};
export default withStyles(styles)(StatusModal);
