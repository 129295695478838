import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, makeStyles, withStyles } from '@material-ui/core';
import styles from './navigation-card.styles';

const useStyles = makeStyles(styles);

const NavigationCard = ({ title, subtitle, actionButton, backgroundColor, backgroundImage }) => {
  const classes = useStyles({ backgroundColor, backgroundImage });

  return (
    <Box className={classes.header}>
      <Box className={classes.headerContent}>
        <Typography variant="h3" className={classes.headerTitle}>
          {title}
        </Typography>

        {subtitle && (
          <Typography variant="body1" className={classes.subtitle}>
            {subtitle}
          </Typography>
        )}
      </Box>
      {actionButton && <>{actionButton}</>}
    </Box>
  );
};

NavigationCard.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.element]),
  actionButton: PropTypes.element,
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.string,
};

export default NavigationCard;
