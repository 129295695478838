export default (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
  },
  table: {
    width: '100%',
    borderRadius: 6,
    border: `1px solid ${theme.palette.greyScale[60]}`,
    borderCollapse: 'separate',
  },
  tableHeadRow: {
    borderRadius: '5px 5px 0px 0px',
    background: theme.palette.greyScale[50],
    borderCollapse: 'separate',
  },
  tableCell: {
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 12px',
    gap: 10,
    width: '100%',
  },
  tableBodyRow: {
    borderTop: `0.5px solid ${theme.palette.greyScale[60]}`,
    borderBottom: `0.5px solid ${theme.palette.greyScale[60]}`,
    background: theme.palette.primary.white,
  },
  tableBodyText: {
    color: theme.palette.greyScale[110],
    textTransform: 'capitalize',
    flex: '1 1 0',
  },
  lastTableBodyRow: {
    borderRadius: '0px 0px 5px 5px',
  },
  bold: {
    fontWeight: 700,
  },
  chip: {
    '&.MuiChip-root': {
      border: '1px solid',
      textTransform: 'capitalize',
    },
  },
  completedChip: {
    '&.MuiChip-outlined': {
      borderColor: theme.palette.green.deep,
      color: theme.palette.green.deep,
      background: theme.palette.green.pale,
    },
  },
  inProgressChip: {
    '&.MuiChip-outlined': {
      borderColor: theme.palette.yellow.main,
      color: theme.palette.yellow.deep,
      background: theme.palette.yellow.pale,
    },
  },
  completedChipIcon: {
    color: `${theme.palette.green.main} !important`,
  },
  inProgressChipIcon: {
    color: `${theme.palette.yellow.main} !important`,
  },
});
